import React, { useEffect } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import FormRequest from "../components/FormRequest";
import  {ProfileData} from "../components/ProfileData";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Col, Row, Button, Card, ListGroup, Modal, Accordion } from 'react-bootstrap';


const NewFormRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);
  return (
    <Layout>
        <Row className="justify-content-md-center">
            <Col lg="6">
            <ProfileData />
            </Col>
            </Row>
    </Layout>
  );
};

export default NewFormRequest;