
import React, { useRef, useState, useEffect } from 'react';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';
import { getMe } from "../features/authSlice";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../components/assets/logo.svg';
import COC from '../components/assets/Code_of_Conduct.pdf';
import Acknowledgement from '../components/assets/Acknowledgement Form.pdf';
import "../components/CSS/Formitem2Styles.css";
import axios from "axios";


const FormItem2 = () => {
    const fullNameRef = useRef(null);
    const positionRef = useRef(null);
    const dateRef = useRef(null);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [signatureImage, setSignatureImage] = useState(null);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const { isError,user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (user) {
            fullNameRef.current.value = user.name || "";
            setSignatureImage("https://back-itonline.algosaibi.sa/" + user?.profileImage || null);
        }
    }, [user]);
    useEffect(() => {
        dispatch(getMe());
        if (isError) navigate("/");
      }, [dispatch, isError, navigate]);

    const handleTermsClick = (e) => {
        e.preventDefault();
        window.open(COC, '_blank');
    };


    useEffect(() => {
        const now = new Date();
        const formattedDateTime = now.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });
        setCurrentDateTime(formattedDateTime);
    }, []);

    const isArabic = (text) => {
        const arabicPattern = /[\u0600-\u06FF]/;
        return arabicPattern.test(text);
    };

    const handleBilingualChange = (e) => {
        const text = e.target.value;
        if (isArabic(text.charAt(0))) {
            e.target.style.direction = 'rtl';
            e.target.style.textAlign = 'right';
        } else {
            e.target.style.direction = 'ltr';
            e.target.style.textAlign = 'left';
        }
    };

    const collectFormData = () => {
        return {
            fullName: fullNameRef.current.value,
            position: positionRef.current.value,
            date: dateRef.current.value,
        };
    };


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const formData = collectFormData();
    //     console.log('Form Submitted:', formData);
    //     // Handle form submission logic here
    // };

    const uploadToServer = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
    
        try {
          const response = await axios.post(
            "https://back-itonline.algosaibi.sa/api/upload",
            formData
          );
          console.log("File uploaded successfully:", response.data);
          return response.data.file;
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      };
// Please Check, CORS worked success
      const centerText = (page, text, y, size, font) => {
        const textWidth = font.widthOfTextAtSize(text, size);
        const pageWidth = page.getWidth();
        const x = (pageWidth - textWidth) / 2;
        page.drawText(text, { x, y, size, font });
      };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const fullName = fullNameRef.current.value;
        const position = positionRef.current.value;

        // Load the PDF
        const existingPdfBytes = await fetch(Acknowledgement).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        // Embed the font
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Get the first page of the document
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        const { width, height } = firstPage.getSize();

        centerText(firstPage, fullName, height - 520, 12, font);
        centerText(firstPage, fullName, height - 230, 12, font);
        centerText(firstPage, position, height - 715, 12, font);
        centerText(firstPage, currentDateTime, height - 645, 12, font);


        if (signatureImage) {
            const signatureImageBytes = await fetch(signatureImage).then(res => res.arrayBuffer());
            const signatureImageEmbed = await pdfDoc.embedPng(signatureImageBytes);
            const signatureDims = signatureImageEmbed.scale(0.5); // Adjust scale as needed
            firstPage.drawImage(signatureImageEmbed, {
                x: 120,
                y: 210,
                width: signatureDims.width,
                height: signatureDims.height,
            });
        }
        // Save the PDF
        const pdfBytes = await pdfDoc.save();

        // Create a blob from the PDF bytes
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfFile = new File([blob], `Acknowledgement_Form_${fullName}.pdf`, { type: 'application/pdf' });


        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Acknowledgement_Form_Filled.pdf';
        link.click();

        
        try {
            const uploadedFilePath = await uploadToServer(pdfFile);
            console.log("PDF uploaded to server:", uploadedFilePath);
            
            const formData = new FormData();
            // formData.append('file', new Blob([pdfBytes], { type: 'application/pdf' }), 'Acknowledgement_Form_Filled.pdf');
            formData.append('fullName', fullName);
            formData.append('position', position);
            formData.append('email', user.email); // Assuming user email is available in the user object
            // const response = await fetch('https://back-itonline.algosaibi.sa/api/send-pdf-email', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //       },
            //       credentials: 'include',
            //     body: formData,
            // });
            // const response =  await axios.post("https://back-itonline.algosaibi.sa/api/send-pdf-email", formData);

            // const result = await response.json();
            // if (result.success) {
            //     alert('PDF has been sent to your email.');
            // } else {
            // }
            const response = await emailjs.send(
                'service_oxpdr8r',      // Service ID
                'template_uldxb2u',     // Template ID
                {
                    fullName: fullName,
                    fileLink: `https://back-itonline.algosaibi.sa/${uploadedFilePath}`,
                    email: user.email

                },
                'yWsPpiwqu-w5qYFZr'     // Public Key from Email.js
            );
            // Now directly access the response object
            if (response && response.status === 200) {
                alert(`PDF has been sent to your email: ${user.email}`);
            } else {
                alert('Failed to send email. Please try again.');
            }
    
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
   try {
    const formData = {
        fullName,
        position,
        date: currentDateTime,
        email: user.email
    };

    const response = await fetch('https://back-itonline.algosaibi.sa/api/update-excel', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData),
    });

    const result = await response.json();
    
    if (response.ok) {
        if (result.success) {
            console.log('Excel sheet updated successfully');
            // Maybe show a success message to the user
        } else {
            throw new Error(result.message);
        }
    } else {
        throw new Error(`HTTP error! status: ${response.status}, message: ${result.message}`);
    }
} catch (error) {
    console.error('Error updating Excel sheet:', error);
    // Show an error message to the user
    if (error.message.includes('might be open in another application')) {
        //alert('Unable to update the Excel sheet. Please close the file if it\'s open and try again.');
    } else {
       // alert('An error occurred while updating the Excel sheet. Please try again later.');
    }
}
        
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh', backgroundColor: '#F5F5F5' }}>
            <div className="card shadow-lg p-3" style={{ maxWidth: '800px', width: '100%', borderRadius: '10px', backgroundColor: '#fff' }}>
                {/* Logo */}
                <div className="text-end mb-4">
                    <img src={logo} alt="Khalifa Algosaibi Group Logo" style={{ width: '150px' }} />
                </div>

                <div className="text-center mb-3">
                    <h2 className="mb-2">لائحة السلوك المهني لمجموعة خليفة القصيبي - نموذج إقرار</h2>
                    <h2>Khalifa Algosaibi Group Code of Conduct - Acknowledgement Form</h2>
                </div>
                <hr style={{
                    borderRadius: '10px',
                    border: '3px solid #35ab8e',
                    height: '4px',
                    backgroundColor: '#35ab8e',
                    margin: '25px 0'
                }} />

                <div className="d-flex justify-content-between mb-4">
                    {/* English Section */}
                    <div className="w-50 pe-3">
                        <p>(Attachment 1)</p>
                        <p>I hereby confirm that I have received, read, and understand the attached Code of Conduct.</p>
                        <p>I understand that it is my responsibility to comply with all the company policies, including the Code of Conduct contained within, as a condition of my employment with Algosaibi Group.</p>
                        <p>I hereby sign this copy of the Code of Conduct to signify that I have read and understood it and agreed to comply with it.</p>
                    </div>

                    {/* Arabic Section */}
                    <div className="w-50 ps-3 text-end" dir="rtl">
                        <p>(مرفق رقم ١)</p>
                        <p>أنا أقر بأنني استلمت وقرأت وفهمت لائحة السلوك المرفقة.</p>
                        <p>أدرك أنه من مسؤوليتي الالتزام بجميع سياسات الشركة بما في ذلك لائحة السلوك المهني المرفقة كشرط من شروط عملي في مجموعة القصيبي.</p>
                        <p>أوقّع بموجب هذا على هذه النسخة من لائحة السلوك المهني للدلالة على أنني قرأتها وفهمتها ووافقت على الالتزام بها.</p>
                    </div>
                </div>

                {/* Form Fields */}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="fullName" className="form-label">Employee/Contractor Name Printed (اسم الموظف/المقاول مطبوعًا):</label>
                        <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            name="fullName"
                            ref={fullNameRef}
                            onChange={handleBilingualChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="position" className="form-label">Position (المسمى الوظيفي):</label>
                        <input
                            type="text"
                            className="form-control"
                            id="position"
                            name="position"
                            ref={positionRef}
                            onChange={handleBilingualChange}
                            placeholder="Enter your position"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date and Time (التاريخ والوقت):</label>
                        <input
                            type="text"
                            className="form-control"
                            id="date"
                            name="date"
                            value={currentDateTime}
                            disabled
                            readOnly
                        />
                    </div>
                    {signatureImage && (
                        <div className="mb-3">
                            <label className="form-label">Your Signature:</label>
                            <img src={signatureImage} alt="User Signature" style={{ maxWidth: '200px' }} />
                        </div>
                    )}
                    <div className="mb-3 form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="agreeTerms"
                            checked={agreedToTerms}
                            onChange={(e) => setAgreedToTerms(e.target.checked)}
                            required
                        />
                        <label className="form-check-label" htmlFor="agreeTerms">
                            I agree to the <a href="#" onClick={handleTermsClick}>terms and conditions</a>
                        </label>
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn btn-custom w-50" disabled={!agreedToTerms}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormItem2;