
// import React, { useState, useEffect, useRef } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import logo from "./assets/logo.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { LogOut, reset } from "../features/authSlice";
// import { fetchNotifications, markNotificationAsRead } from "../features/notificationSlice";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Bell } from 'react-bootstrap-icons';

// const Navbar = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { user } = useSelector((state) => state.auth);
//   const { items: notifications = [] } = useSelector((state) => state.notifications);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const notificationRef = useRef(null);

//   useEffect(() => {
//     dispatch(fetchNotifications());
//     const interval = setInterval(() => {
//       dispatch(fetchNotifications());
//     }, 30000);

//     // Add click event listener to close dropdown when clicking outside
//     const handleClickOutside = (event) => {
//       if (notificationRef.current && !notificationRef.current.contains(event.target)) {
//         setShowNotifications(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       clearInterval(interval);
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [dispatch]);

//   const logout = () => {
//     dispatch(LogOut());
//     dispatch(reset());
//     navigate("/");
//   };

//   const handleNotificationClick = () => {
//     setShowNotifications(!showNotifications);
//   };

//   const handleMarkAsRead = (uuid) => {
//     dispatch(markNotificationAsRead(uuid));
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light">
//       <div className="container-fluid d-flex justify-content-between">
//         <NavLink to="/dashboard" className="navbar-brand">
//           <img src={logo} width="112" height="28" alt="logo" />
//         </NavLink>

//         <div className="d-flex align-items-center">
//           <div className="position-relative me-3" ref={notificationRef}>
//             <Bell 
//               size={24} 
//               onClick={handleNotificationClick} 
//               style={{ cursor: 'pointer' }}
//             />
//             {notifications.length > 0 && (
//               <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
//                 {notifications.length}
//               </span>
//             )}
//             {showNotifications && (
//               <div className="position-absolute top-100 end-0 mt-2 bg-white border rounded shadow-sm" style={{ width: '300px', maxHeight: '400px', overflowY: 'auto', zIndex: 1000 }}>
//                 {notifications.length > 0 ? (
//                   notifications.map((notification) => (
//                     <div key={notification.uuid} className="p-2 border-bottom">
//                       <p className="mb-1 small">{notification.message}</p>
//                       <button 
//                         className="btn btn-sm btn-outline-secondary py-0 px-1"
//                         style={{ fontSize: '0.75rem' }}
//                         onClick={() => handleMarkAsRead(notification.uuid)}
//                       >
//                         Mark as Read
//                       </button>
//                     </div>
//                   ))
//                 ) : (
//                   <p className="p-2 text-center small">No new notifications</p>
//                 )}
//               </div>
//             )}
//           </div>
//           <button onClick={logout} className="btn btn-sm btn-light">
//             Log Out
//           </button>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Bell, List } from "react-bootstrap-icons";
import { CgProfile } from "react-icons/cg";
import { IoLogOut } from "react-icons/io5";
import logo from "./assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotifications, markNotificationAsRead } from "../features/notificationSlice";
import { LogOut, reset } from "../features/authSlice";

const Navbar = ({ isMobileView }) => {
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const dropdownRef = useRef(null);
    const notificationRef = useRef(null);

    const { items: notifications = [] } = useSelector((state) => state.notifications);

    useEffect(() => {
        dispatch(fetchNotifications());
        const interval = setInterval(() => dispatch(fetchNotifications()), 30000);

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            clearInterval(interval);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    const logout = () => {
        dispatch(LogOut());
        dispatch(reset());
    };

    const handleNotificationClick = () => setShowNotifications(!showNotifications);

    return (
        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid d-flex justify-content-between">
                <NavLink to="/dashboard" className="navbar-brand">
                    <img src={logo} width="112" height="28" alt="logo" />
                </NavLink>

                <div className="d-flex align-items-center">
                    {isMobileView && (
                        <div className="dropdown me-3">
                            <button
                                className="btn btn-light dropdown-toggle"
                                type="button"
                                onClick={() => setShowDropdown(!showDropdown)}
                            >
                                <List size={24} />
                            </button>
                            {showDropdown && (
                                <div className="dropdown-menu show" ref={dropdownRef}>
                                    <NavLink className="dropdown-item" to="/dashboard">
                                        Dashboard
                                    </NavLink>
                                    <NavLink className="dropdown-item" to="/profile">
                                        <CgProfile /> Profile
                                    </NavLink>
                                    <button onClick={logout} className="dropdown-item">
                                        <IoLogOut /> Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="position-relative me-3" ref={notificationRef}>
                        <Bell
                            size={24}
                            onClick={handleNotificationClick}
                            style={{ cursor: "pointer" }}
                        />
                        {notifications.length > 0 && (
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {notifications.length}
                            </span>
                        )}
                        {showNotifications && (
                            <div
                                className="position-absolute top-100 end-0 mt-2 bg-white border rounded shadow-sm"
                                style={{ width: "300px", maxHeight: "400px", overflowY: "auto", zIndex: 1000 }}
                            >
                                {notifications.length > 0 ? (
                                    notifications.map((notification) => (
                                        <div key={notification.uuid} className="p-2 border-bottom">
                                            <p className="mb-1 small">{notification.message}</p>
                                            <button
                                                className="btn btn-sm btn-outline-secondary py-0 px-1"
                                                style={{ fontSize: "0.75rem" }}
                                                onClick={() => dispatch(markNotificationAsRead(notification.uuid))}
                                            >
                                                Mark as Read
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p className="p-2 text-center small">No new notifications</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;