import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const ApprovedFormList = () => {
    const { user } = useSelector((state) => state.auth);
    const [formResponses, setFormResponses] = useState([]);
    const [sortField, setSortField] = useState("created_at");
    const [sortDirection, setSortDirection] = useState("desc");


    const sortedFormResponses = [...formResponses].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
        return 0;
    });

    

    useEffect(() => {
        getFormResponses();
      }, []);
    

    const getFormResponses = async () => {
        try {
          const response = await axios.get("https://back-itonline.algosaibi.sa/forms", {
            withCredentials: true,
          });
    
          setFormResponses(response.data);
        } catch (error) {
          console.error("Error fetching form responses", error);
        }
      };

    // Include other necessary functions from UserFormList.jsx
    // such as sorting, rendering, etc.

    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    };
    const getFileExtension = (filename) => {
        if (!filename) return '';
        const parts = filename.split('.');
        return parts.length > 1 ? '.' + parts.pop() : '';
      };

    const downloadAllDocuments = async (response) => {
        try {
            const documents = [
                response.quotient && { url: `https://back-itonline.algosaibi.sa/${response.quotient}`, name: `Quotient1${getFileExtension(response.quotient)}` },
                response.quotienttwo && { url: `https://back-itonline.algosaibi.sa/${response.quotienttwo}`, name: `Quotient2${getFileExtension(response.quotienttwo)}` },
                response.quotientthree &&  { url: `https://back-itonline.algosaibi.sa/${response.quotientthree}`, name: `Quotient3${getFileExtension(response.quotientthree)}` },
                { url: `https://back-itonline.algosaibi.sa/uploads/${response.pdf_blob}`, name: 'ApprovalRequest.pdf' }
            ].filter(doc => doc.url); // Filter out any undefined documents

            for (const doc of documents) {
                const pdfResponse = await axios.get(doc.url, {
                    responseType: "blob",
                    withCredentials: true,
                });

                const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", doc.name);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (error) {
            console.error("Error downloading documents", error);
            alert("Failed to download documents: " + (error.response?.data?.msg || error.message));
        }
    };

    const renderSortIcon = (field) => {
        if (sortField !== field) return <FaSort />;
        return sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />;
    };

    function findPendingApproverIndex(response) {
        const finalStatus = response.status.toLowerCase();
        if (finalStatus === "approved") {
          return "Approved";
        }
        return ""; // Return an empty string for non-approved statuses
      }
    const url = "https://back-itonline.algosaibi.sa/";

    return (
        <div>
            <h1 className="title">Approved Forms</h1>
            <h2 className="subtitle">List of approved forms</h2>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Form ID</th> 
                        <th onClick={() => handleSort("form_type")}>
                            Form Type {renderSortIcon("form_type")}
                        </th>
                        <th onClick={() => handleSort("created_at")}>
                            Date {renderSortIcon("created_at")}
                        </th>
                        <th onClick={() => handleSort("status")}>
                            Final Status {renderSortIcon("status")}
                        </th>
                        <th>Approval requests</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedFormResponses
                    .filter(response => response.status.toLowerCase() === "approved")
                    .map((response, index) => (
                        <tr key={response.uuid}>
                            <td>{index + 1}</td>
                            <td>{response.id}</td>
                            <td>{response.form_type}</td>
                            <td>{new Date(response.created_at).toLocaleDateString()}</td>
                            <td>
                                {/* {response.status === "approved" ? (
                  <span
                    style={{
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    {response.status.toUpperCase()}
                  </span>
                ) : ( */}
                                <span
                                    style={{
                                        color: "black",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* {response.status.toUpperCase()} */}
                                    {findPendingApproverIndex(response)}
                                </span>
                                {/* )} */}
                            </td>
                            {/* <td>
              <Link
                to={`/forms/view/${response.uuid}`}
                className="button is-small is-info"
              >
                View
              </Link>
            </td> */}
                            <td>
                                <button
                                    onClick={() => downloadAllDocuments(response)}
                                    className="button is-small is-primary"
                                >
                                    Download All
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ApprovedFormList;
