import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./components/Login";
import Users from "./pages/Users";
import AddUser from "./pages/AddUser";
import EditUser from "./pages/EditUser";
import AddProduct from "./pages/AddProduct";
import EditProduct from "./pages/EditProduct";
import NewFormRequest from "./pages/NewFormRequest";
import Forms from "./pages/Forms";
import EditProfile from "./pages/EditProfile";
import FormAFEPage from "./pages/forms/FormAFEPage";
import FormItem2Page from "./pages/forms/FormItem2Page";
import Drafts from "./pages/Drafts";
import EditFormPage from "./pages/EditFormPage";
import RequestForm from "./components/RequestForm";
import RequestList from "./components/RequestList";
import Companies from "./pages/Company";
import EditCompany from "./pages/EditCompany";
import ApprovedForms from "./pages/ApprovedForms";
import EditDepartment from "./pages/EditDepartment";
import Department from "./pages/Department";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/form-responses" element={<Forms />} /> {/* New Route */}
          <Route path="/form-request" element={<NewFormRequest />} />{" "}
          {/* New Route */}
          <Route path="/profile" element={<EditProfile />} /> {/* New Route */}
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/departments" element={<Department />} />
          <Route path="/company/edit/:id" element={<EditCompany />} />
          <Route path="/department/edit/:id" element={<EditDepartment />} />
          <Route path="/drafted-forms" element={<Drafts />} />
          <Route path="/edit-form/:formType/:uuid" element={<EditFormPage />} />
          <Route path="/Forms" element={<Forms />} />
          <Route path="/products/add" element={<AddProduct />} />{" "}
          {/* كلمة products جايه من "الباك اند" ء */}
          <Route path="/products/edit/:id" element={<EditProduct />} />{" "}
          {/* كلمة products جايه من "الباك اند" ء */}
          {/* <Route path="/FormAFEPage/edit/:id" element={<FormAFEPage />} /> */}
          <Route path="/FormAFEPage" element={<FormAFEPage />} />
          {/* <Route path="/FormItem2Page/edit/:id" element={<FormItem2Page />} /> */}
          <Route path="/FormItem2Page" element={<FormItem2Page />} />
          <Route path="/request" element={<RequestForm />} />
          <Route path="/requests" element={<RequestList />} />
          <Route path="/approved-forms" element={<ApprovedForms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
