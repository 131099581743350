import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';

const DraftedForms = () => {
  const [drafts, setDrafts] = useState([]);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log('User:', user);
    if (user?.id || user?.uuid) {
      fetchDrafts();
    }
  }, [user]);

  const fetchDrafts = async () => {
    try {
      console.log('Fetching drafts...');
      const response = await axios.get('https://back-itonline.algosaibi.sa/drafts', {
        withCredentials: true
      });
      console.log('Drafts response:', response.data);
      setDrafts(response.data);
    } catch (error) {
      console.error('Error fetching drafts:', error);
    }
  };

  const deleteDraft = async (uuid) => {
    try {
      await axios.delete(`https://back-itonline.algosaibi.sa/drafts/${uuid}`, {
        withCredentials: true
      });
      fetchDrafts();
      alert('Draft deleted successfully');
    } catch (error) {
      console.error('Error deleting draft:', error);
      alert('Failed to delete draft');
    }
  };

  console.log('Current drafts state:', drafts);

  return (
    <div>
      <h1 className="title">Drafted Forms</h1>
      <h2 className="subtitle">List of your saved drafts</h2>
      {drafts.length === 0 ? (
        <p>No drafts found.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Form Type</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {drafts.map((draft, index) => {
    console.log('Draft object:', draft);
    return (
              <tr key={draft.uuid}>
                <td>{index + 1}</td>
                <td>{draft.form_type}</td>
                <td>{new Date(draft.created_at).toLocaleString() }</td>

                <td>
                  <Link to={`/edit-form/${draft.form_type}/${draft.uuid}`}
                    className="button is-small is-info" style={{ marginRight: 10 }}
                  >
                    Edit
                  </Link>
                  <button 
                    className="button is-small is-danger"
                    onClick={() => deleteDraft(draft.uuid)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default DraftedForms;
