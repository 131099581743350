import React from 'react';
import { useSelector } from 'react-redux';

const RequestDetails = ({ requestId }) => {
    const request = useSelector(state => 
        state.request.requests.find(req => req.id === requestId)
    );

    if (!request) return <div>Request not found</div>;

    return (
        <div>
            <h3>Request Details</h3>
            <p>ID: {request.id}</p>
            <p>Status: {request.status}</p>
            <p>Created At: {new Date(request.createdAt).toLocaleString()}</p>
            <h4>Approvals:</h4>
            {request.manager_approvals && request.manager_approvals.map(approval => (
                <div key={approval.id}>
                    <p>Manager: {approval.Manager.name}</p>
                    <p>Status: {approval.status}</p>
                    <p>Sequence Order: {approval.sequence_order}</p>
                </div>
            ))}
        </div>
    );
};

export default RequestDetails;