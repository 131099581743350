import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { updatedPdf } from "./updatedPdf";
import { Link } from "react-router-dom";

const UserFormList = () => {
  const [formResponses, setFormResponses] = useState([]);
  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const sortedFormResponses = [...formResponses].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const renderSortIcon = (field) => {
    if (sortField !== field) return <FaSort />;
    return sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const statusOptions = ["Approved", "Rejected", "Pending"];
  const { user, managers } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    getFormResponses();
  }, []);

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const getFormResponses = async () => {
    try {
      const response = await axios.get("https://back-itonline.algosaibi.sa/forms", {
        withCredentials: true,
      });
      setFormResponses(response.data);
    } catch (error) {
      console.error("Error fetching form responses", error);
    }
  };

  const parseJSONSafely = (data) => {
    if (typeof data === "string") {
      try {
        return JSON.parse(data);
      } catch (error) {
        return null;
      }
    }
    return data;
  };

  const changeSubManagerStatus = async (formId, newStatus, response) => {
    let confirmMessage = "";

    if (newStatus.toLowerCase() === "approved") {
      confirmMessage = "Are you sure you want to approve this form?";
    } else if (newStatus.toLowerCase() === "rejected") {
      confirmMessage = "Are you sure you want to reject this form?";
    }

    if (confirmMessage && !window.confirm(confirmMessage)) {
      return; // If the user cancels, exit the function early
    }

    let managerStatuses = parseJSONSafely(response?.managerStatuses);

    if (!user.profileImage || user.profileImage === "null") {
      return alert("Please upload your signature first");
    }

    managerStatuses = managerStatuses.map((item) => {
      if (item.userId === user?.uuid) {
        return { ...item, status: newStatus };
      }
      return item;
    });

    const allApproved = managerStatuses.every(
      (item) => item.status.toLowerCase() === "approved"
    );
    const isFinal = allApproved && response.status.toLowerCase() === "pending";

    const managerIndex = managerStatuses.findIndex(
      (manager) => manager.userId === user.uuid
    );

    const updatedBlob = await updatedPdf(
      managerStatuses,
      managers,
      response,
      managerIndex
    );
    const pdfBlob = new Blob([updatedBlob], { type: "application/pdf" });
    const formDataForBackendPdf = new FormData();
    formDataForBackendPdf.append("file", pdfBlob, "AFE-Form.pdf");
    formDataForBackendPdf.append(
      "managerStatuses",
      JSON.stringify(managerStatuses)
    );
    formDataForBackendPdf.append("isFinal", isFinal ? true : "");
    formDataForBackendPdf.append("status", isFinal ? newStatus : "");

    try {
      const result = await axios.patch(
        `https://back-itonline.algosaibi.sa/forms/supervisor/status/update/${formId}`,
        formDataForBackendPdf,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.status === 200) {
        getFormResponses();
        alert("Form status updated successfully");
      }
    } catch (error) {
      console.error("Error updating form status:", error);
    }
  };

  const isDisabled = (response) => {
    const managerStatuses = parseJSONSafely(response?.managerStatuses);

    const currentUserIndex = managerStatuses.findIndex(
      (item) => item.userId === user?.uuid
    );

    if (currentUserIndex === -1) return true;

    if (currentUserIndex > 0) {
      const previousUserStatus =
        managerStatuses[currentUserIndex - 1].status.toLowerCase();
      const currentUserStatus =
        managerStatuses[currentUserIndex].status.toLowerCase();

      if (
        previousUserStatus === "approved" &&
        currentUserStatus === "pending"
      ) {
        return false;
      }
    }

    const allApproved = managerStatuses.every(
      (item) => item.status.toLowerCase() === "approved"
    );

    if (allApproved && response.status.toLowerCase() === "pending") {
      return currentUserIndex !== 0;
    } else if (currentUserIndex === 0) {
      return managerStatuses[0].status.toLowerCase() !== "pending";
    }

    return true;
  };

  const getStatus = (response) => {
    const managerStatuses = parseJSONSafely(response?.managerStatuses);

    const allApproved = managerStatuses.every(
      (item) => item.status.toLowerCase() === "approved"
    );
    if (
      allApproved &&
      response.status.toLowerCase() === "pending" &&
      user?.uuid == managerStatuses[0].userId
    ) {
      return "pending";
    } else {
      return managerStatuses
        ?.find((item) => item.userId === user?.uuid)
        ?.status.toLowerCase();
    }
  };

  const getManagerName = (id) => {
    const name = managers?.find((item) => item.uuid === id)?.name;
    return name;
  };

  function findPendingApproverIndex(response) {
    const finalStatus = response.status.toLowerCase();
    const approversArray = parseJSONSafely(response.managerStatuses);
    for (let i = 0; i < approversArray.length; i++) {
      if (approversArray[i].status === "pending") {
        return `Pending from ${getManagerName(approversArray[i].userId)}`;
      }
      if (approversArray[i].status === "rejected") {
        return `Rejected from ${getManagerName(approversArray[i].userId)}`;
      }
      if (finalStatus === "approved") {
        return `Approved`;
      } else if (finalStatus === "rejected") {
        for (let i = 0; i < approversArray.length; i++) {
          if (approversArray[i].status === "rejected") {
            return `Rejected from ${getManagerName(approversArray[i].userId)} `;
          }
        }
        if (finalStatus === "rejected") {
          return `Rejected from Super manager`;
        }
      } else if (finalStatus === "pending") {
        for (let i = 0; i < approversArray.length; i++) {
          if (approversArray[i].status === "pending") {
            return `Pending from ${getManagerName(approversArray[i].userId)}`;
          }
        }
        if (finalStatus === "pending") {
          return `Pending from Super manager`;
        }
      } else {
        return "Not found";
      }
    }
  }

  const url = "https://back-itonline.algosaibi.sa/";

  return (
    <div>
      <h1 className="title">Forms-new</h1>
      <h2 className="subtitle">List of your submitted forms</h2>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th onClick={() => handleSort("form_type")}>
              Form Type {renderSortIcon("form_type")}
            </th>
            <th>Form ID</th>
            <th onClick={() => handleSort("created_at")}>
              Created At {renderSortIcon("created_at")}
            </th>
            <th onClick={() => handleSort("status")}>Final Status {renderSortIcon("status")}</th>
            {user?.role == "manager" ? <th>Actions</th> : ""}
            <th>Quotients</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedFormResponses
            .filter((response) => response.status.toLowerCase() !== "approved")
            .map((response, index) => (
              <tr key={response.uuid}>
                <td>{index + 1}</td>
                <td>{response.form_type}</td>
                <td>{response.id}</td>
                <td>{new Date(response.created_at).toLocaleString()}</td>
                <td>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {findPendingApproverIndex(response)}
                  </span>
                </td>
                {user?.role == "manager" ? (
                  <td>
                    <Dropdown
                      options={statusOptions}
                      id={`status-${response.uuid}`}
                      name="status"
                      selectedValue={getStatus(response)}
                      onChange={(newStatus) =>
                        changeSubManagerStatus(
                          response.uuid,
                          newStatus,
                          response
                        )
                      }
                      isDisabled={isDisabled(response)}
                    />
                  </td>
                ) : (
                  ""
                )}
                <td className="">
                  {response.quotient ? (
                    <a
                      href={url + response.quotient}
                      download
                      className="button is-small is-primary"
                      style={{ marginTop: 4 }}
                      target="_blank"
                    >
                      Quotient 1
                    </a>
                  ) : (
                    ""
                  )}
                  <br />
                  {response.quotienttwo ? (
                    <a
                      href={url + response.quotienttwo}
                      download
                      className="button is-small is-primary"
                      style={{ marginTop: 4 }}
                      target="_blank"
                    >
                      Quotient 2
                    </a>
                  ) : (
                    ""
                  )}
                  <br />

                  {response.quotientthree ? (
                    <a
                      href={url + response.quotientthree}
                      download
                      className="button is-small is-primary"
                      style={{ marginTop: 4 }}
                      target="_blank"
                    >
                      Quotient 3
                    </a>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <a
                    href={`https://back-itonline.algosaibi.sa/uploads/${response.pdf_blob}`}
                    className="button is-small is-primary mr-2"
                    style={{ marginRight: 10 }}
                    target="_blank"
                    download
                  >
                    Download PDF
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserFormList;

const Dropdown = ({
  options,
  id,
  name,
  selectedValue,
  onChange,
  isDisabled,
}) => {
  return (
    <select
      id={id}
      name={name}
      value={selectedValue}
      onChange={(e) => onChange(e.target.value)}
      disabled={isDisabled}
    >
      {options.map((option) => (
        <option key={option} value={option.toLowerCase()}>
          {option}
        </option>
      ))}
    </select>
  );
};
