// import React from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { IoPerson, IoPricetag, IoHome, IoLogOut,IoNewspaper, IoAdd, IoSettings  } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
// import { LogOut, reset } from "../features/authSlice";

// const Sidebar = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { user } = useSelector((state) => state.auth);

//   const logout = () => {
//     dispatch(LogOut());
//     dispatch(reset());
//     navigate("/");
//   };

//   return (
//     <div>
//       <aside className="menu pl-3 has-shadow">
//         <p className="menu-label">General</p>
//         <ul className="menu-list">
//         <li>
//             <NavLink to={"/form-request"}>
//             <IoAdd /> New Form Request
//             </NavLink>
//             </li>
//           <li>
//             <NavLink to={"/dashboard"}>
//               <IoHome /> Dashboard
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to={"/Forms"}>
//             <IoNewspaper /> My Forms
//             </NavLink>
//           </li>

//           <li>
//             <NavLink to={"/profile"}>  {/* Add the Profile Link */}
//               <IoSettings /> Profile
//             </NavLink>
//           </li>

//         </ul>
//         {user && user.role === "admin" && (
//           <div>
//             <p className="menu-label">Admin</p>
//             <ul className="menu-list">
//               <li>
//                 <NavLink to={"/users"}>
//                   <IoPerson /> Users
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         )}

//         <p className="menu-label">Settings</p>
//         <ul className="menu-list">
//           <li>
//             <button onClick={logout} className="button is-white">
//               <IoLogOut /> Logout
//             </button>
//           </li>
//         </ul>
//       </aside>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  IoPerson,
  IoPricetag,
  IoHome,
  IoLogOut,
  IoNewspaper,
  IoAdd,
  IoSettings,
  IoCheckmarkCircle,
} from "react-icons/io5";
import { CgProfile } from "react-icons/cg";

import { RiDraftFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import { Collapse, Button } from "react-bootstrap";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  // State for controlling the collapse of "New Form Request" dropdown
  const [open, setOpen] = useState(false);

  return (
    <div>
      <aside className="menu pl-3 has-shadow">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          {/* {user && user.role !== "manager" && ( */}
            <li>
              {/* Toggle collapse on button click */}
              <Button
                onClick={() => setOpen(!open)}
                aria-controls="collapse-form-request"
                aria-expanded={open}
                variant="light"
                className="text-left"
              >
                <IoAdd /> New Form Request
              </Button>

              {/* Collapsible form dropdown */}

              <Collapse in={open}>
                <div id="collapse-form-request">
                  <ul className="menu-list">
                    <li>
                      <NavLink to={"/FormAFEPage"}>AFE Form </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/FormItem2Page"}> Code of Conduct Form</NavLink>
                    </li>
                  </ul>
                </div>
              </Collapse>
            </li>
          {/* )} */}
          {user && user.role === "admin" && (
            <li>
              <NavLink to={"/dashboard"}>
                <IoHome /> Dashboard
              </NavLink>
            </li>
          )}

          <li>
            <NavLink to={"/Forms"}>
              <IoNewspaper /> Form Requests
            </NavLink>
          </li>
          <li>
            <NavLink to={"/approved-forms"}>
              <IoCheckmarkCircle /> Approved Forms
            </NavLink>
          </li>

          {/* {user && user.role !== "manager" && ( */}
            <li>
              <NavLink to={"/drafted-forms"}>
                <RiDraftFill /> Drafted Form Request
              </NavLink>
            </li>
           {/* )} */}

        </ul>

        {user && user.role === "admin" && (
          <div>
            <p className="menu-label">Admin</p>
            <ul className="menu-list">
              <li>
                <NavLink to={"/users"}>
                  <IoPerson /> Users
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        {user && user.role === "admin" && (
          <div>
            <ul className="menu-list">
              <li>
                <NavLink to={"/companies"}>
                  <IoPerson /> Companies
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        {user && user.role === "admin" && (
          <div>
            <ul className="menu-list">
              <li>
                <NavLink to={"/departments"}>
                  <IoPerson /> Responsible Authority
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <p className="menu-label">Settings</p>
        <ul className="menu-list">
        <li>
            <NavLink to={"/profile"}>
              <CgProfile /> Profile
            </NavLink>
          </li>
          <li>
            <button onClick={logout} className="button is-white">
              <IoLogOut /> Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
