import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";

const AdminFormList = () => {
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    getFormResponses();
  }, []);

  // Fetch form responses for the current logged-in user
  const getFormResponses = async () => {
    try {
      const response = await axios.get("https://back-itonline.algosaibi.sa/forms", {
        withCredentials: true, // Ensure credentials like cookies are sent for authentication
      });
      setFormResponses(response.data);
    } catch (error) {
      console.error("Error fetching form responses", error);
    }
  };

  const deleteFormResponse = async (uuid) => {
    try {
      await axios.delete(`https://back-itonline.algosaibi.sa/forms/${uuid}`, {
        withCredentials: true, // Ensure credentials are sent for authentication
      });
      getFormResponses(); // Refresh the list after deleting
      alert("Form response deleted successfully");
    } catch (error) {
      console.error("Error deleting form response", error);
      alert("Failed to delete form response");
    }
  };

  //   const downloadPdf = async (uuid) => {
  //     try {
  //       const response = await axios.get(`http://172.16.1.139:5000/forms/${uuid}/download`, {
  //         responseType: 'blob',
  //         withCredentials: true,
  //       });

  //       if (response.headers['content-type'] === 'application/json') {
  //         // If the response is JSON, it's probably an error message
  //         const reader = new FileReader();
  //         reader.onload = function() {
  //           const errorMessage = JSON.parse(reader.result);
  //           console.error("Server error:", errorMessage);
  //           alert(`Failed to download PDF: ${errorMessage.msg}`);
  //         };
  //         reader.readAsText(response.data);
  //       } else {
  //         // It's a PDF, proceed with download
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         console.log('formResponses:', formResponses);
  //         const safeEmail = formResponses?.user_email || 'unknown';
  //         link.setAttribute('download', `form_${safeEmail}.pdf`);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //       }
  //     } catch (error) {
  //       console.error("Error downloading PDF", error);
  //       alert("Failed to download PDF: " + (error.response?.data?.msg || error.message));
  //     }
  //   };

  const downloadPdf = async (response) => {
    try {
      const pdfResponse = await axios.get(
        `https://back-itonline.algosaibi.sa/forms/${response.uuid}/download`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );

      if (pdfResponse.headers["content-type"] === "application/json") {
        // Handle JSON error response (keep this part as is)
      } else {
        // It's a PDF, proceed with download
        const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
        const link = document.createElement("a");
        link.href = url;
        const safeEmail =
          response.form_type +
            "_" +
            (response.user_email
              ? response.user_email.split("@")[0]
              : "unknown") || "unknown";
        link.setAttribute("download", `${safeEmail}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading PDF", error);
      alert(
        "Failed to download PDF: " +
          (error.response?.data?.msg || error.message)
      );
    }
  };
  return (
    <div>
      <h1 className="title">Forms</h1>
      <h2 className="subtitle">List of your submitted forms</h2>
      {formResponses.length === 0 ? (
        <p>No Response found.</p>
      ) : (
        //   <table className="table is-striped is-fullwidth">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Creator</th>
              <th>Form Type</th>
              <th>Created At</th>
              <th>Final Status</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formResponses?.map((response, index) => (
              <tr key={response}>
                <td>{index + 1}</td>
                <td>
                  {(response?.user_email && response?.user_email.split("@")[0]) ||
                    "Unknown"}
                </td>
                {/* <td>{JSON.stringify(response.form_data)}</td> */}
                <td>{response.form_type}</td>
                <td>{new Date(response.created_at)?.toLocaleString()}</td>
                <td>{response.status}</td>
                <td>
                  <button
                    onClick={() => downloadPdf(response)}
                    className="button is-small is-primary mr-2"
                    style={{ marginRight: 10 }}
                  >
                    Download PDF
                  </button>

                  <button
                    onClick={() => deleteFormResponse(response.uuid)}
                    className="button is-small is-danger"
                  >
                    Delete Response
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          {/* </table> */}
        </Table>
      )}
    </div>
  );
};

export default AdminFormList;
