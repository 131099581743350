import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FormAFE from './FormAFE'; // Import your form components

const EditForm = () => {
  const { formType, uuid } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchDraft = async () => {
      try {
        const response = await axios.get(`https://back-itonline.algosaibi.sa/drafts/${uuid}`, {
          withCredentials: true
        });
        console.log(response.data.form_data, JSON.parse(response.data.form_data))
        const parsedFormData = JSON.parse(response.data.form_data);
        setFormData(parsedFormData);
      } catch (error) {
        console.error('Error fetching draft:', error);
      }
    };

    fetchDraft();
  }, [uuid]);

  if (!formData) return <div>Loading...</div>;
  console.log(formData)
  // Render the appropriate form component based on formType
  switch (formType) {
    case 'AFE_Form':
      return <FormAFE initialData={formData} />;
    // Add cases for other form types
    default:
      return <div>Unknown form type</div>;
  }
};

export default EditForm;