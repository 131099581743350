import React, { useEffect } from "react";
import Layout from "./Layout";
import ProfilePage from "../components/ProfilePage"; // Import the profile form component
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice"; // Assuming this fetches logged-in user info

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layout>
      <ProfilePage />  {/* This will load the profile form */}
    </Layout>
  );
};

export default EditProfile;
