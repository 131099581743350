import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRequests, approveRequest, rejectRequest } from '../features/requestSlice';
import RequestDetails from './RequestDetails';

const RequestList = () => {
    const dispatch = useDispatch();
    const { requests } = useSelector(state => state.request);
    const { user } = useSelector(state => state.auth);
    const [selectedRequestId, setSelectedRequestId] = useState(null);

    useEffect(() => {
        dispatch(getRequests());
    }, [dispatch]);

    const handleApprove = (id) => {
        dispatch(approveRequest(id));
    };

    const handleReject = (id) => {
        dispatch(rejectRequest(id));
    };

    return (
        <div>
            <h2 className="title is-4">Requests</h2>
            <div className="columns">
                <div className="column is-half">
                    {requests.map(request => (
                        <div key={request.id} className="box">
                            <p>Request ID: {request.id}</p>
                            <p>Status: {request.status}</p>
                            <button 
                                className="button is-small is-info" 
                                onClick={() => setSelectedRequestId(request.id)}
                            >
                                View Details
                            </button>
                            {user.role !== 'user' && request.status === 'pending' && (
                                <>
                                    <button 
                                        className="button is-small is-success ml-2" 
                                        onClick={() => handleApprove(request.id)}
                                    >
                                        Approve
                                    </button>
                                    <button 
                                        className="button is-small is-danger ml-2" 
                                        onClick={() => handleReject(request.id)}
                                    >
                                        Reject
                                    </button>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className="column is-half">
                    {selectedRequestId && <RequestDetails requestId={selectedRequestId} />}
                </div>
            </div>
        </div>
    );
};

export default RequestList;