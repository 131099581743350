import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const createRequest = createAsyncThunk("request/create", async (managers, thunkAPI) => {
    try {
        const response = await axios.post('https://back-itonline.algosaibi.sa/requests', { managers });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const approveRequest = createAsyncThunk("request/approve", async (id, thunkAPI) => {
    try {
        const response = await axios.put(`https://back-itonline.algosaibi.sa/requests/${id}/approve`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const rejectRequest = createAsyncThunk("request/reject", async (id, thunkAPI) => {
    try {
        const response = await axios.put(`https://back-itonline.algosaibi.sa/requests/${id}/reject`);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getRequests = createAsyncThunk("request/getAll", async (_, thunkAPI) => {
    try {
        const response = await axios.get('https://back-itonline.algosaibi.sa/requests');
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const requestSlice = createSlice({
    name: "request",
    initialState: {
        requests: [],
        isLoading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createRequest.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.requests.push(action.payload);
            })
            .addCase(createRequest.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload.msg;
            })
            .addCase(approveRequest.fulfilled, (state, action) => {
                const index = state.requests.findIndex(request => request.id === action.payload.id);
                if (index !== -1) {
                    state.requests[index] = action.payload;
                }
            })
            .addCase(rejectRequest.fulfilled, (state, action) => {
                const index = state.requests.findIndex(request => request.id === action.payload.id);
                if (index !== -1) {
                    state.requests[index] = action.payload;
                }
            })
            .addCase(getRequests.fulfilled, (state, action) => {
                state.requests = action.payload;
            });
    }
});

export default requestSlice.reducer;