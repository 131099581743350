// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { getMe } from "../../features/authSlice";
// import Layout from "../Layout";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { Form, Col, Row, Button, Card, ListGroup, Modal, Accordion } from 'react-bootstrap';
// import FormAFE from '../../components/FormAFE';

// const FormAFEPage = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { isError } = useSelector((state) => state.auth);

//     useEffect(() => {
//         dispatch(getMe());
//     }, [dispatch]);

//     useEffect(() => {
//         if (isError) {
//             navigate("/");
//         }
//     }, [isError, navigate]);
//     return (
//         <Layout>
//                   <h1 className="title">Forms</h1>
//                   <h2 className="subtitle">Fixd Asset Requisition Form</h2>
//             <Row className="mb-4">
//             </Row>
//             <Row className="justify-content-md-center">
//                 {/* <Col lg="6"> */}
//                     <FormAFE />
//                 {/* </Col> */}
//             </Row>
//         </Layout>
//     );
// };

// export default FormAFEPage;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import Layout from "../Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Col, Row, Button, Card, ListGroup, Modal, Accordion } from 'react-bootstrap';
import FormAFE from '../../components/FormAFE';

const FormAFEPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);
    
    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const checkMobileView = () => {
            setIsMobileView(window.innerWidth <= 800);
        };

        checkMobileView();
        window.addEventListener("resize", checkMobileView);

        return () => window.removeEventListener("resize", checkMobileView);
    }, []);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);
    return (
        <Layout>
            <div className="mt-5" style={isMobileView ? { paddingLeft: "10px", paddingRight: "10px", marginLeft: "0px", marginRight: "0px", } : {}}>

                <h1 className="title">Forms</h1>
                <h2 className="subtitle">Fixd Asset Requisition Form</h2>
                <Row className="mb-4">
                </Row>
                <Row className="justify-content-md-center">
                    {/* <Col lg="6"> */}
                    <FormAFE />
                    {/* </Col> */}
                </Row>
            </div>
        </Layout>
    );
};

export default FormAFEPage;