import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../features/authSlice";
import requestReducer from "../features/requestSlice";
import notificationReducer from "../features/notificationSlice";



export const store = configureStore({
  reducer: {
    auth: authReducer,
    request: requestReducer,
    notifications: notificationReducer,
  },
});
