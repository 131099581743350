import { PDFDocument, StandardFonts, degrees } from "pdf-lib";
import { Buffer } from "buffer";
import pdfd from "./assets/FA - Annexure - Revised v32.pdf";
import axios from "axios";

export const modifyPdf = async (formData, managers, input1, input2, logoUrl, signatureImage, Justification) => {
  const convertImageUrlToDataUrl = async (imageUrl) => {
    if (!imageUrl) {
      console.warn("No image URL provided");
      return null;
    }
  
    // If it's already a data URL, return it directly
    if (imageUrl.startsWith('data:')) {
      return imageUrl;
    }
  
    try {
      const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });
      const base64Image = Buffer.from(response.data, 'binary').toString('base64');
      const mimeType = response.headers['content-type'];
      return `data:${mimeType};base64,${base64Image}`;
    } catch (error) {
      console.error('Error fetching or converting the image:', error.message);
      return null;
    }
  };
  

console.log("Managers received in modifyPdf:", managers);

  const {
    assetBenefits,
    purposeOfRequest,
    financialYear,
    budgetReference,
    assetClass,
    assetNumber,
    name,
    managerName,
    company,
    department,
    location,
    centreName,
    centreNumber,
    potentialUser,
    employeeNumber,
    assetDescription,
    specification,
    quantityRequested,
    totalEstimatedCost,
  } = formData;
  const signature = await convertImageUrlToDataUrl(formData.signature);

  const url = pdfd;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const embedImage = async (imageDataUrl) => {
    if (!imageDataUrl) {
      throw new Error("Image data URL is undefined");
    }
  
    const base64String = imageDataUrl.split(",")[1];
    if (!base64String) {
      throw new Error("Base64 string for image is empty");
    }
  
    let imagePdf;
    if (imageDataUrl.startsWith("data:image/png;base64")) {
      imagePdf = await pdfDoc.embedPng(base64String);
    } else if (
      imageDataUrl.startsWith("data:image/jpeg;base64") ||
      imageDataUrl.startsWith("data:image/jpg;base64")
    ) {
      imagePdf = await pdfDoc.embedJpg(base64String);
    } else {
      throw new Error("Unsupported image type");
    }
    return imagePdf;
  };

  console.log("Received signature image:", signatureImage);

  let signatureImagePdf;
  const signatureDataUrl = await convertImageUrlToDataUrl(signatureImage);
  if (signatureDataUrl) {
    try {
      signatureImagePdf = await embedImage(signatureDataUrl);
      console.log("Embedded signature");
    } catch (error) {
      console.error("Error embedding signature:", error);
    }
  }

  let logoImagePdf;
  const logoDataUrl = await convertImageUrlToDataUrl(logoUrl);
  if (logoDataUrl) {
    try {
      logoImagePdf = await embedImage(logoDataUrl);
      console.log("Embedded logo");
    } catch (error) {
      console.error("Error embedding logo:", error);
    }
  }

  console.log(managers, managerName);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { width, height } = firstPage.getSize();


  if (logoImagePdf) {
    const targetX = 500;
    const targetY = height - 70;
    const targetWidth = 100;
    const targetHeight = 50;
    
    let logoDims;
    
    if (logoImagePdf.width > logoImagePdf.height) {
      logoDims = logoImagePdf.scale(targetWidth / logoImagePdf.width);
    } else {
      logoDims = logoImagePdf.scale(targetHeight / logoImagePdf.height);
    }
    
    const centeredX = targetX - logoDims.width / 2;
    
    firstPage.drawImage(logoImagePdf, {
      x: centeredX,
      y: targetY,
      width: logoDims.width,
      height: logoDims.height,
    });
  }

  // console.log("Justification:", Justification);

  // Draw the signature
  if (signatureImagePdf) {
    firstPage.drawImage(signatureImagePdf, {
      x: 140,
      y: 345,
      width: 100,
      height: 60,
      rotate: degrees(0),
    });
  }
  firstPage.drawText(`${name}`, {
    x: 157.5,
    y: 405,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${managerName}`, {
    x: 320,
    y: 405,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });

  firstPage.drawText(`${company}`, {
    x: 440,
    y: 700,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${assetClass}`, {
    x: 440,
    y: 688.5,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${assetNumber}`, {
    x: 440,
    y: 676,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${department}`, {
    x: 130,
    y: 654,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${location}`, {
    x: 438,
    y: 653.5,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${centreName}`, {
    x: 130,
    y: 643,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${centreNumber}`, {
    x: 508,
    y: 643,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });

  firstPage.drawText(`${potentialUser}`, {
    x: 298,
    y: 632,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${employeeNumber}`, {
    x: 298,
    y: 620,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });

  const maxLength = 100; // Adjust this value as needed
  const lineHeight = 12; // Adjust this value based on your desired line spacing

  // Function to split text into lines
  const splitTextIntoLines = (text, maxLength) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    words.forEach((word) => {
      if ((currentLine + word).length <= maxLength) {
        currentLine += (currentLine ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });
    if (currentLine) {
      lines.push(currentLine);
    }
    return lines;
  };

  const lines = splitTextIntoLines(assetDescription, maxLength);

  // Draw each line
  lines.forEach((line, index) => {
    firstPage.drawText(line, {
      x: 130,
      y: 606 - index * lineHeight, // Adjust y-position for each line
      size: 9,
      font: helveticaFont,
      rotate: degrees(0),
    });
  });

  firstPage.drawText(`${specification}`, {
    x: 130,
    y: 569,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });

  const quantityRequestedText = `${quantityRequested}`;
  const quantityRequestedWidth = helveticaFont.widthOfTextAtSize(
    quantityRequestedText,
    9
  );
  const centerX = (532 + 539) / 2; // Calculate the center point
  const quantityRequestedX = centerX - quantityRequestedWidth / 2; // Center the text

  firstPage.drawText(quantityRequestedText, {
    x: quantityRequestedX,
    y: 539,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${input2}`, {
    x: 423,
    y: 539,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${input1}`, {
    x: 290,
    y: 539,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  const totalEstimatedCostText = `${totalEstimatedCost}`;
  const totalEstimatedCostWidth = helveticaFont.widthOfTextAtSize(
    totalEstimatedCostText,
    10.5
  );
  const totalEstimatedCostCenterX = (270 + 310) / 2; // Calculate the center point
  const totalEstimatedCostX =
    totalEstimatedCostCenterX - totalEstimatedCostWidth / 2; // Center the text

  firstPage.drawText(totalEstimatedCostText, {
    x: totalEstimatedCostX,
    y: 525,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });

  firstPage.drawText(`${budgetReference}`, {
    x: 275,
    y: 507,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${Justification}`, {
      x: 229,
      y: 488,
      size: 9,
      font: helveticaFont,
      rotate: degrees(0),
  });

  firstPage.drawText(`${assetBenefits}`, {
    x: 229,
    y: 442,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${purposeOfRequest}`, {
    x: 229,
    y: 464,
    size: 9,
    font: helveticaFont,
    rotate: degrees(0),
  });
  firstPage.drawText(`${financialYear}`, {
    x: 533,
    y: 506,
    size: 10.5,
    font: helveticaFont,
    rotate: degrees(0),
  });
  // firstPage.drawText(`${Budget}`, {
  //     x: 157.5,
  //     y: 130,
  //     size: 10.5,
  //     font: helveticaFont,
  //     rotate: degrees(0),
  // });
  if (managers && managers.length > 0) {
    const date = new Date();

    // Format the date to MM-DD-YYYY
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`;

    // firstPage.drawImage(signatureImagePdf, {
    //   x: 138,
    //   y: 372,
    //   width: 100,
    //   height: 25,
    //   rotate: degrees(0),
    // });
    firstPage.drawText(formattedDate, {
      x: 141,
      y: 348,
      width: 100,
      height: 8,
      size: 6,
      font: helveticaFont,
      rotate: degrees(0),
    });
    // firstPage.drawText(managers[0], {
    //   x: 320,
    //   y: 402.5,
    //   size: 7.5,
    //   font: helveticaFont,
    //   rotate: degrees(0),
    // });
    firstPage.drawText(managers[0], {
      x: 450,
      y: 405,
      size: 9,
      font: helveticaFont,
      rotate: degrees(0),
    });
    firstPage.drawText(managers[1], {
      x: 141,
      y: 210,
      width: 100,
      height: 8,
      size: 8,
      font: helveticaFont,
      rotate: degrees(0),
    });
    firstPage.drawText(managers[2], {
      x: 320,
      y: 210,
      width: 100,
      height: 8,
      size: 8,
      font: helveticaFont,
      rotate: degrees(0),
    });
    firstPage.drawText(managers[3], {
      x: 450,
      y: 210,
      width: 100,
      height: 8,
      size: 8,
      font: helveticaFont,
      rotate: degrees(0),
    });
    firstPage.drawText(managers[4], {
      x: 320,
      y: 143,
      width: 100,
      height: 8,
      size: 8,
      font: helveticaFont,
      rotate: degrees(0),
    });
    firstPage.drawText(managers[5], {
      x: 450,
      y: 143,
      width: 100,
      height: 8,
      size: 8,
      font: helveticaFont,
      rotate: degrees(0),
    });
  }

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};
