

// import React, { useState, useEffect } from 'react';
// import Layout from './Layout';
// import Welcome from '../components/Welcome';
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, Link } from "react-router-dom";
// import { getMe } from "../features/authSlice";
// import FA from '../components/assets/FormImage/FA_Annexure_Revised_v2.jpg';
// import Acknowledgement from '../components/assets/FormImage/Acknowledgement_Form.jpg';
// import '../components/CSS/styles.css';
// import { Card, Button, Modal } from 'react-bootstrap';

// const Dashboard = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { isError, user } = useSelector((state) => state.auth);

//     useEffect(() => {
//         dispatch(getMe());
//     }, [dispatch]);

//     useEffect(() => {
//         if (isError) {
//             navigate('/');
//         }
//     }, [isError, navigate]);

//     const formTypes = [
//         {
//             name: "Form AFE",
//             path: "/FormAFEPage",
//             description: "This form is used for Authorization for Expenditure.",
//             imageSrc: FA
//         },
//         {
//             name: "Code of Conduct",
//             path: "/FormItem2Page",
//             description: "This Code of Conduct (the “Code”) reflects Khalifa Algosaibi Investment CJSC, its branches and its subsidiaries (“KAI” or “Company”) business ethics. It reinforces our corporate commitment to maintaining high ethical standards and summarizes the fundamental importance of acting with integrity in all we do",
//             imageSrc: Acknowledgement // Set to null if no image is available
//         },

//         // Add more form types here
//     ];

//     const ImageWithFallback = ({ src, alt }) => {
//         const [imageLoaded, setImageLoaded] = useState(false);
//         const [imageError, setImageError] = useState(false);

//         useEffect(() => {
//             setImageLoaded(false);
//             setImageError(false);
//         }, [src]);

//         if (!src || imageError) {
//             return (
//                 <div className="bg-light h-100 d-flex align-items-center justify-content-center">
//                     <span>In Progress</span>
//                 </div>
//             );
//         }

//         return (
//             <>
//                 {!imageLoaded && (
//                     <div className="bg-light h-100 d-flex align-items-center justify-content-center">
//                         <div className="spinner-border spinner-custom" role="status">
//                             <span className="visually-hidden">Loading...</span>
//                         </div>
//                     </div>
//                 )}
//                 <img
//                     src={src}
//                     alt={alt}
//                     className={`img-fluid rounded-start h-100 object-fit-cover ${imageLoaded ? '' : 'd-none'}`}
//                     onLoad={() => setImageLoaded(true)}
//                     onError={() => setImageError(true)}
//                 />
//             </>
//         );
//     };

//     return (
//         <Layout>
//             <Welcome />
//             {user && user.role !== "manager" && (
//                 <div className="mt-5">
//                     <div className="row row-cols-1 row-cols-md-2 g-4">
//                         {formTypes.map((form, index) => (
//                             <div key={index} className="col">
//                                 <Card className="h-100">
//                                     <div className="row g-0 h-100">
//                                         <div className="col-md-4">
//                                             <ImageWithFallback src={form.imageSrc} alt={form.name} className="img-fluid rounded-start h-100 object-fit-cover" />
//                                         </div>
//                                         <div className="col-md-8 d-flex flex-column">
//                                             <Card.Body className="d-flex flex-column">
//                                                 <Card.Title>{form.name}</Card.Title>
//                                                 <Card.Text className="description-text">
//                                                     {form.description.length > 250
//                                                         ? `${form.description.substring(0, 250)}...`
//                                                         : form.description}
//                                                 </Card.Text>
                                               
//                                             </Card.Body>
//                                             <Card.Footer className="bg-transparent border-0">
//                                                 <Link to={form.path} className="btn btn-custom w-100">
//                                                     Create Form
//                                                 </Link>
//                                             </Card.Footer>
//                                         </div>
//                                     </div>
//                                 </Card>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             )}
//         </Layout>
//     );
// };

// export default Dashboard;

import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Welcome from '../components/Welcome';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getMe } from "../features/authSlice";
import FA from '../components/assets/FormImage/FA_Annexure_Revised_v2.jpg';
import Acknowledgement from '../components/assets/FormImage/Acknowledgement_Form.jpg';
import '../components/CSS/styles.css';
import { Card } from 'react-bootstrap';

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError, user } = useSelector((state) => state.auth);

    const [isMobileView, setIsMobileView] = useState(false);

    useEffect(() => {
        const checkMobileView = () => {
            setIsMobileView(window.innerWidth <= 800);
        };

        checkMobileView();
        window.addEventListener("resize", checkMobileView);

        return () => {
            window.removeEventListener("resize", checkMobileView);
        };
    }, []);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate('/');
        }
    }, [isError, navigate]);

    const formTypes = [
        {
            name: "Code of Conduct",
            path: "/FormItem2Page",
            description: "This Code of Conduct (the “Code”) reflects Khalifa Algosaibi Investment CJSC, its branches and its subsidiaries (“KAI” or “Company”) business ethics. It reinforces our corporate commitment to maintaining high ethical standards and summarizes the fundamental importance of acting with integrity in all we do.",
            imageSrc: Acknowledgement, // Set to null if no image is available
        },
        {
            name: "Form AFE",
            path: "/FormAFEPage",
            description: "This form is used for Authorization for Expenditure.",
            imageSrc: FA,
            isDesktopOnly: true,
        }
    ];

    const ImageWithFallback = ({ src, alt }) => {
        const [imageLoaded, setImageLoaded] = useState(false);
        const [imageError, setImageError] = useState(false);

        useEffect(() => {
            setImageLoaded(false);
            setImageError(false);
        }, [src]);

        if (!src || imageError) {
            return (
                <div className="bg-light h-100 d-flex align-items-center justify-content-center">
                    <span>In Progress</span>
                </div>
            );
        }

        return (
            <>
                {!imageLoaded && (
                    <div className="bg-light h-100 d-flex align-items-center justify-content-center">
                        <div className="spinner-border spinner-custom" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                <img
                    src={src}
                    alt={alt}
                    className={`img-fluid rounded-start h-100 object-fit-cover ${imageLoaded ? '' : 'd-none'}`}
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageError(true)}
                />
            </>
        );
    };

    return (
        <Layout>
            <div
                className="mt-5"
                style={
                    isMobileView
                        ? { paddingLeft: "10px", paddingRight: "10px", marginLeft: "0px", marginRight: "0px" }
                        : {}
                }
            >
                <Welcome />
                <br />
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    {formTypes.map((form, index) => {
                        if (form.isDesktopOnly && isMobileView) {
                            return (
                                <div key={index} className="col">
                                    <Card className="h-100">
                                        <Card.Body className="d-flex align-items-center justify-content-center">
                                            <Card.Text style={{ color: "#ff8383" }}>
                                                {form.name} is only accessible on desktop devices.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            );
                        }
                        return (
                            <div key={index} className="col">
                                <Card className="h-100">
                                    <div className="row g-0 h-100">
                                        <div className="col-md-4">
                                            <ImageWithFallback
                                                src={form.imageSrc}
                                                alt={form.name}
                                                className="img-fluid rounded-start h-100 object-fit-cover"
                                            />
                                        </div>
                                        <div className="col-md-8 d-flex flex-column">
                                            <Card.Body className="d-flex flex-column">
                                                <Card.Title>{form.name}</Card.Title>
                                                <Card.Text className="description-text">
                                                    {form.description.length > 250
                                                        ? `${form.description.substring(0, 250)}...`
                                                        : form.description}
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className="bg-transparent border-0">
                                                <Link to={form.path} className="btn btn-custom w-100">
                                                    Create Form
                                                </Link>
                                            </Card.Footer>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
