// import React, { useState, useEffect } from "react";
// import Navbar from "../components/Navbar";
// import Sidebar from "../components/Sidebar";

// const Layout = ({ children }) => {
//   const [isMobileView, setIsMobileView] = useState(false);

//   useEffect(() => {
//     const checkMobileView = () => {
//       setIsMobileView(window.innerWidth <= 800);
//     };

//     checkMobileView();
//     window.addEventListener('resize', checkMobileView);

//     return () => window.removeEventListener('resize', checkMobileView);
//   }, []);

//   const mobileMessageStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: 'rgba(0, 0, 0, 0.8)', // semi-transparent background
//     color: 'white',
//     textAlign: 'center',
//     zIndex: 1000,
//     visibility: isMobileView ? 'visible' : 'hidden'
//   };
//   const messageBoxStyle = {
//     backgroundColor: '#35ab8e',
//     padding: '20px',
//     borderRadius: '5px',
//     maxWidth: '80%'
//   };


//   return (
//     <React.Fragment>
//       <div style={mobileMessageStyle}>
//         <p>The application is under progress in mobile version. Please switch to desktop version.</p>
//       </div>
//       <Navbar />
//       <div className="columns mt-0" style={{ minHeight: "100vh" }}>
//         <div className="column is-2" style={{ minWidth: "30vh", display: isMobileView ? 'none' : 'block' }}>
//           <Sidebar />
//         </div>
//         <div className="column has-background-light" style={{ display: isMobileView ? 'none' : 'block' }}>
//           <main>{children}</main>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default Layout;

import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Layout = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth <= 800);
    };

    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  return (
    <React.Fragment>
      <Navbar isMobileView={isMobileView} />
      <div className="columns mt-0" style={{ minHeight: "100vh" }}>
        {!isMobileView && (
          <div className="column is-2" style={{ minWidth: "30vh" }}>
            <Sidebar />
          </div>
        )}
        <div className="column has-background-light">
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;