import React, { useEffect } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import UserFormList from "../components/UserFormList";
import AdminFormList from "../components/AdminFormList";

const Forms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, user, navigate]);

  return (
    <Layout>
            {user && user.role === "admin" ? (
        <AdminFormList />
      ) : (
        <UserFormList />
      )}
    </Layout>
  );
};

export default Forms;
