import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Col,
  Row,
  Button,
  Accordion,
  InputGroup,
  Card,
} from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice"; // Assuming you have a slice for authentication
import download from "downloadjs";

export const ProfileData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError } = useSelector((state) => state.auth); // Pull user data from the state
  const [selectedForm, setSelectedForm] = useState("");
  const [input1, setInput1] = useState(0);
  const [input2, setInput2] = useState(0);
  const [signatureImage, setSignatureImage] = useState(null);
  const sigPad1 = useRef(null);

  // Refs for form fields
  const nameRef = useRef();
  const emailRef = useRef();
  const managerNameRef = useRef();
  const companyRef = useRef();
  const selectRef = useRef();
  const assetRef = useRef();
  const departmentRef = useRef();
  const locationRef = useRef();
  const centreNameRef = useRef();
  const centreNumberRef = useRef();
  const potentialUserRef = useRef();
  const employeeUNRef = useRef();
  const assetDescriptionRef = useRef();
  const specificationRef = useRef();
  const QuantityRef = useRef();
  const TotalEstimatedcostRef = useRef();
  const additionalThoughtRef = useRef();
  const BudgetRef = useRef();
  const financialYearRef = useRef();
  const purposeOfRequestRef = useRef();
  const assetBenefitsRef = useRef();

  const currentYear = new Date().getFullYear();

  // Get authenticated user when component loads
  useEffect(() => {
    dispatch(getMe());
    if (isError) navigate("/");
  }, [dispatch, isError, navigate]);

  // Handle form selection change
  const handleSelectChange = (event) => {
    setSelectedForm(event.target.value);
  };

  const uploadPdfToServer = async (pdfBytes, formType, userId) => {
    // Create a new FormData object
    const formDataForBackend = new FormData();

    // Append the PDF file (blob) to the form data
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    formDataForBackend.append("file", blob, "filled_form.pdf");

    // Append additional data: form type and user ID
    formDataForBackend.append("form_type", formType); // e.g., 'Form_AFE'
    formDataForBackend.append("user_id", userId); // e.g., '123'

    try {
      // Send the form data to the backend
      const response = await fetch("http://localhost:3000/api/upload-pdf", {
        method: "POST",
        body: formDataForBackend,
      });

      const result = await response.json();
      console.log("PDF uploaded successfully:", result);
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };

  const handleSignatureUpload = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // setUploadedSignature(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid PNG or JPEG file.");
    }
  };

  // Save drawn signature
  const saveSignature = () => {
    if (!sigPad1.current.isEmpty()) {
      const signature = sigPad1.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureImage(signature);
    } else {
      alert("Signature canvas is empty.");
    }
  };

  // Clear drawn signature
  const clearSignature = () => {
    sigPad1.current.clear();
    setSignatureImage(null);
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();

    const formValues = {
      user_id: user?.id, // Get the current user's ID from authentication
      form_data: {
        name: nameRef.current.value,
        email: emailRef.current.value,
        managerName: managerNameRef.current.value,
        company: companyRef.current.value,
        assetClass: selectRef.current.value,
        assetNumber: assetRef.current.value,
        department: departmentRef.current.value,
        location: locationRef.current.value,
        profitCentre: {
          centreName: centreNameRef.current.value,
          centreNumber: centreNumberRef.current.value,
        },
        potentialUser: potentialUserRef.current.value,
        employeeNumber: employeeUNRef.current.value,
        assetDescription: assetDescriptionRef.current.value,
        specification: specificationRef.current.value,
        quantityRequested: {
          additional: input1,
          replacement: input2,
          total: input1 + input2,
        },
        totalEstimatedCost: TotalEstimatedcostRef.current.value,
        additionalThought: additionalThoughtRef.current.value,
        budgetReference: BudgetRef.current.value,
        financialYear: currentYear,
        purposeOfRequest: purposeOfRequestRef.current.value,
        assetBenefits: assetBenefitsRef.current.value,
        signature: sigPad1.current?.toDataURL() || null, 
      },
      form_type: selectedForm, 
    };

    try {
      const response = await fetch("https://back-itonline.algosaibi.sa/forms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formValues), // Submit the form with the current user's ID
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="container-fluid mt-5">
      <Accordion defaultActiveKey="0" alwaysOpen>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Form Selection</Accordion.Header>
          <Accordion.Body>
            <Form.Select onChange={handleSelectChange}>
              <option value="" disabled selected>
                Choose Form
              </option>
              <option value="Form AFE">Form AFE</option>
              <option value="Form Item #2">Form Item #2</option>
            </Form.Select>
          </Accordion.Body>
        </Accordion.Item>

        {selectedForm === "Form AFE" && (
          <Accordion.Item eventKey="2">
            <Accordion.Header>Form AFE</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={onSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        ref={nameRef}
                        defaultValue={user?.name}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        ref={emailRef}
                        defaultValue={user?.email}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="formManager">
                  <Form.Label>Manager Name</Form.Label>
                  <Form.Control type="text" ref={managerNameRef} />
                </Form.Group>

                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formCompany">
                      <Form.Label>Company</Form.Label>
                      <Form.Control type="text" ref={companyRef} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formAsset">
                      <Form.Label>Asset Class</Form.Label>
                      <Form.Select ref={selectRef}>
                        <option value="">Choose...</option>
                        <option value="AC Hardware">AC Hardware</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formAssetNumber">
                      <Form.Label>Asset No. (MS Dynamics)</Form.Label>
                      <Form.Control type="text" ref={assetRef} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formDepartment">
                      <Form.Label>Department</Form.Label>
                      <Form.Control type="text" ref={departmentRef} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formLocation">
                      <Form.Label>Location</Form.Label>
                      <Form.Control type="text" ref={locationRef} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formCentreName">
                      <Form.Label>Profit / Cost Centre Name</Form.Label>
                      <Form.Control type="text" ref={centreNameRef} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formCentreNumber">
                      <Form.Label>Profit / Cost Centre Number</Form.Label>
                      <Form.Control type="text" ref={centreNumberRef} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formPotentialUser">
                    <Form.Label>Potential User</Form.Label>
                    <Form.Control type="text" ref={potentialUserRef} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formEmployeeNumber">
                    <Form.Label>Employee NO.</Form.Label>
                    <Form.Control type="text" ref={employeeUNRef} />
                  </Form.Group>
                </Row>

                <Form.Group controlId="formDescription">
                  <Form.Label>Asset Description</Form.Label>
                  <Form.Control type="text" ref={assetDescriptionRef} />
                </Form.Group>

                <Form.Group controlId="formSpecification">
                  <Form.Label>
                    Specification / Details of Requested Asset
                  </Form.Label>
                  <Form.Control type="text" ref={specificationRef} />
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Quantity Requested</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>Additional</InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={input1}
                        onChange={(e) => setInput1(Number(e.target.value))}
                      />
                      <InputGroup.Text>Replacement</InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={input2}
                        onChange={(e) => setInput2(Number(e.target.value))}
                      />
                      <InputGroup.Text>Total</InputGroup.Text>
                      <Form.Control
                        type="number"
                        value={input1 + input2}
                        ref={QuantityRef}
                        readOnly
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Form.Group controlId="formTotalCost">
                  <Form.Label>Total Estimated Cost</Form.Label>
                  <Form.Control type="number" ref={TotalEstimatedcostRef} />
                </Form.Group>

                <Form.Group controlId="formAdditionalThought">
                  <Form.Label>Additional Thought</Form.Label>
                  <Form.Control type="text" ref={additionalThoughtRef} />
                </Form.Group>

                <Form.Group controlId="formBudget">
                  <Form.Label>
                    Budget / Post-Budget Approval Reference
                  </Form.Label>
                  <Form.Control type="text" ref={BudgetRef} />
                </Form.Group>

                <Form.Group controlId="formFinancialYear">
                  <Form.Label>Financial Year</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentYear}
                    ref={financialYearRef}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formPurpose">
                  <Form.Label>Purpose of Request</Form.Label>
                  <Form.Control type="text" ref={purposeOfRequestRef} />
                </Form.Group>

                <Form.Group controlId="formAssetBenefits">
                  <Form.Label>Asset Benefits</Form.Label>
                  <Form.Control type="text" ref={assetBenefitsRef} />
                </Form.Group>

                <Card className="mb-3">
                  <Card.Header>Signature</Card.Header>
                  <Card.Body>
                    <SignatureCanvas
                      ref={sigPad1}
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas",
                      }}
                    />
                    <Button variant="primary" onClick={saveSignature}>
                      Save Drawn Signature
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={clearSignature}
                      className="ms-2"
                    >
                      Clear
                    </Button>
                    <Form.Group
                      controlId="formSignatureUpload"
                      className="mt-3"
                    >
                      <Form.Label>
                        Or Upload Signature (PNG or JPEG) (Optional)
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={handleSignatureUpload}
                      />
                    </Form.Group>
                    {/* {handleSignatureUpload && (
                      <img src={handleSignatureUpload} alt="Uploaded Signature" style={{ width: '200px', marginTop: '10px' }} />
                    )} */}
                  </Card.Body>
                </Card>

                <Button variant="primary" type="submit">
                  Submit Form
                </Button>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};
