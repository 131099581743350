import axios from "axios";
import { PDFDocument, StandardFonts, degrees } from "pdf-lib";
import { Buffer } from "buffer";

const getLastUserIdWithApprovedOrRejectedStatus = (statuses) => {
  for (let i = statuses.length - 1; i >= 0; i--) {
    const { userId, status } = statuses[i];
    if (status === "approved" || status === "rejected") {
      return userId; // Return the userId of the last matching status
    }
  }
  return null; // Return null if no matching status is found
};

const convertImageUrlToDataUrl = async (imageUrl) => {
  try {
    // Ensure the image URL is correctly formatted
    const fullUrl = `${imageUrl}`;

    // Fetch the image from the URL
    const response = await axios.get(fullUrl, {
      responseType: "arraybuffer",
    });

    // Convert the image buffer to a Base64 string
    const base64Image = Buffer.from(response.data, "binary").toString("base64");

    // Get the MIME type of the image
    const mimeType = response.headers["content-type"];

    // Return the full Base64 data URI
    const base64String = `data:${mimeType};base64,${base64Image}`;
    return base64String;
  } catch (error) {
    console.error("Error fetching or converting the image:", error.message);
    return null;
  }
};

export const updatedPdf = async (
  managerStatuses,
  managers,
  response,
  managerIndex
) => {
  const existingPdfBytes = await fetch(
    `https://back-itonline.algosaibi.sa/uploads/${response.pdf_blob}`
  ).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  // Get the first page of the document
  const firstPage = pdfDoc.getPage(0);
  const manager = getLastUserIdWithApprovedOrRejectedStatus(managerStatuses);
  const managerProfile = managers.find((m) => m.uuid === manager);
  const signature = await convertImageUrlToDataUrl(
    "https://back-itonline.algosaibi.sa/" + managerProfile.profileImage
  );

  const embedImage = async (imageDataUrl) => {
    if (!imageDataUrl) {
      throw new Error("Image data URL is undefined");
    }

    // Extract the base64 part of the data URL
    const base64String = imageDataUrl.split(",")[1];

    let signatureImagePdf;
    if (imageDataUrl.startsWith("data:image/png;base64")) {
      signatureImagePdf = await pdfDoc.embedPng(base64String);
    } else if (
      imageDataUrl.startsWith("data:image/jpeg;base64") ||
      imageDataUrl.startsWith("data:image/jpg;base64")
    ) {
      signatureImagePdf = await pdfDoc.embedJpg(base64String);
    } else {
      throw new Error("Unsupported image type");
    }
    return signatureImagePdf;
  };

  let signatureImagePdf1 = await embedImage(signature);

  if (signatureImagePdf1) {
    const date = new Date();

    // Format the date to MM-DD-YYYY
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`;

    if (managerIndex == 0) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 320,
        y: 345,
        width: 100,
        height: 60,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 320,
        y: 348,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 1) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 450,
        y: 345,
        width: 100,
        height: 60,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 450,
        y: 348,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 2) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 150,
        y: 180,
        width: 60,
        height: 33,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 150,
        y: 183,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 3) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 320,
        y: 180,
        width: 60,
        height: 33,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 320,
        y: 183,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 4) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 460,
        y: 180,
        width: 60,
        height: 33,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 460,
        y: 183,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 5) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 320,
        y: 120,
        width: 60,
        height: 25,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 320,
        y: 114,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    } else if (managerIndex == 6) {
      firstPage.drawImage(signatureImagePdf1, {
        x: 460,
        y: 120,
        width: 60,
        height: 25,
        rotate: degrees(0),
      });
      firstPage.drawText(formattedDate, {
        x: 460,
        y: 114,
        width: 100,
        height: 8,
        size: 6,
        font: helveticaFont,
        rotate: degrees(0),
      });
    }
    console.log("Drew signatureImagePdf1");
  }

  const pdfBytes = await pdfDoc.save();

  return pdfBytes;
};
