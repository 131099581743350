import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Col,
  Row,
  Button,
  Accordion,
  InputGroup,
  Card,
  Table,
  Dropdown,
  Modal,
} from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import download from "downloadjs";
import { modifyPdf } from "./modifyPdf";
import axios from "axios";
import AGS from "../components/assets/AGS.png";
import KAI from "../components/assets/KAI.jpg";
import "../components/CSS/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const FormAFE = ({ initialData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError } = useSelector((state) => state.auth);
  const [input1, setInput1] = useState(0);
  const [input2, setInput2] = useState(0);
  const [signatureImage, setSignatureImage] = useState(null);
  const sigPad1 = useRef(null);
  const [managers, setManagers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [directManager, setDirectManager] = useState({});
  const [remainingManagers, setRemainingManagers] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('SAR');
  const [amount, setAmount] = useState('');
  const currencies = ['SAR', 'USD', 'EUR', 'GBP'];

  const handleCostChange = (e) => {
    const numericValue = e.target.value.replace(/[^\d.]/g, '');
    setAmount(numericValue);
  };

  const updateTotalEstimatedCost = () => {
    return `${selectedCurrency} ${amount}`;
  };

  // States for each file input
  const [quotientOne, setQuotientOne] = useState("");
  const [quotientTwo, setQuotientTwo] = useState("");
  const [quotientThree, setQuotientThree] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [locations, setLocations] = useState([
    "Head Office",
    "Warehouse",
    "Factory",
  ]); // Add all your locations here
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    setFilteredLocations(
      locations.filter((location) =>
        location.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, locations]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !searchTerm ||
                child.props.children
                  .toLowerCase()
                  .startsWith(searchTerm.toLowerCase())
            )}
          </ul>
        </div>
      );
    }
  );

  const [searchTermAssetClass, setSearchTermAssetClass] = useState("");
  // const [assetClasses] = useState([
  //   "Air Conditioners",
  //   "Cleaning Equipments",
  //   "Electric and Electronic Items",
  //   "Furniture and Fittings",
  //   "Kitchen Equipments",
  //   "Laundry Equipments",
  //   "Maintenance Items",
  //   "IT Networking Equipments",
  //   "Safety Equipments",
  //   "Sports and Gym Items",
  //   "IT Hardware Equipment",
  //   "IT Telecommunication Equipment",
  //   "IT Software",
  //   "IT Mobile Phones and IPads",
  //   "Forklifts",
  //   "Packing Equipment",
  //   "Storage and Refrigerating Equipments",
  //   "Tricycles",
  //   "Trucks",
  //   "Safe and Cash Box",
  //   "Bobcat",
  //   "Bus and Vans",
  //   "Cars",
  //   "Leasehold Improvements",
  //   "Office Buildings",
  //   "Portable Buildings",
  //   "Residential Building",
  //   "Vacant Land",
  //   "Land with Buildings",
  // ]);
  const [filteredAssetClasses, setFilteredAssetClasses] = useState([]);
  const [selectedAssetClass, setSelectedAssetClass] = useState("");

  useEffect(() => {
    setFilteredAssetClasses(
      allItems.filter((item) =>
        item.toLowerCase().includes(searchTermAssetClass.toLowerCase())
      )
    );
  }, [allItems, searchTermAssetClass]);

  // useEffect(() => {
  //   const department = departments.find(
  //     (department) => department.name === selectedDepartment
  //   );
  //   setFilteredAssetClasses(
  //     department?.items.filter((assetClass) =>
  //       assetClass.toLowerCase().includes(searchTermAssetClass.toLowerCase())
  //     )
  //   );
  // }, [searchTermAssetClass, selectedDepartment]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchTermDepartment, setSearchTermDepartment] = useState("");

  const [departmentsList] = useState([
    "Group IT",
    "Group SCM",
    "Group HR",
    "Group HR - Recruitement",
    "Group HR - Employee Relations",
    "Group Treasury",
    "AGS - Finance",
    "AGS - Operations",
    "AGS - QHSE",
    "AGS - CMU",
    "AGS - Bussiness Development",
    "KAI - Maintenance",
    "KAI - Finance",
    "CMCI - Finance",
    "AVI - Finance",
    "SICAST - Finance",
    "ASAS - Finance",
    "KAC - Finance",
    "KAT - Finance",
  ]);
  const [KAIdepartmentsList] = useState([
    "Group IT",
    "Group SCM",
    "Group HR",
    "Group HR - Recruitement",
    "Group HR - Employee Relations",
    "Group Treasury",
    "KAI - Maintenance",
    "KAI - Finance",
  ]);
  const [AGSdepartmentsList] = useState([
    "AGS - Finance",
    "AGS - Operations",
    "AGS - QHSE",
    "AGS - CMU",
    "AGS - Bussiness Development",
  ]);
  const [SICASTdepartmentsList] = useState([
    "SICAST - Finance",
  ]);

  const [FushidepartmentsList] = useState([
    "Group IT",
    "Group SCM",
    "Group HR",
    "Group HR - Recruitement",
    "Group HR - Employee Relations",
    "Group Treasury",
    "KAI - Maintenance",
    "KAI - Finance",
  ]);

  const [selectedDepartmentList, setSelectedDepartmentList] = useState("");
  const [searchTermDepartmentList, setSearchTermDepartmentList] = useState("");

  const filteredDepartments = departmentsList.filter((department) =>
    department.toLowerCase().includes(searchTermDepartmentList.toLowerCase())
  );

  useEffect(() => {
    if (departmentRef.current) {
      departmentRef.current.value = selectedDepartmentList;
    }
  }, [selectedDepartmentList]);

  // const filteredDepartments = departments
  //   .filter((department) =>
  //     department?.name
  //       .toLowerCase()
  //       .includes(searchTermDepartment.toLowerCase())
  //   )
  //   .map((department) => department.name);

  // useEffect(() => {
  //   if (departmentRef.current) {
  //     departmentRef.current.value = selectedDepartment;
  //   }
  // }, [selectedDepartment]);

  const SignatureField = () => (
    <div
      style={{
        border: "1px solid #dee2e6",
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f8f9fa",
        borderRadius: "4px",
      }}
    >
      Signature Area
    </div>
  );
  // Refs for form fields
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const managerNameRef = useRef(null);
  const companyRef = useRef(null);
  const managerRef = useRef(null);
  const selectRef = useRef(null);
  const assetRef = useRef(null);
  const departmentRef = useRef(null);
  const locationRef = useRef(null);
  const centreNameRef = useRef(null);
  const centreNumberRef = useRef(null);
  const potentialUserRef = useRef(null);
  const employeeUNRef = useRef(null);
  const assetDescriptionRef = useRef(null);
  const specificationRef = useRef(null);
  const TotalEstimatedcostRef = useRef(null);
  const additionalThoughtRef = useRef(null);
  const BudgetRef = useRef(null);
  const JustificationRef = useRef(null);
  const financialYearRef = useRef(null);
  const purposeOfRequestRef = useRef(null);
  const assetBenefitsRef = useRef(null);
  //const justificationRef = useRef();
  const [userName, setUserName] = useState(user?.name || "");
  const [userEmail, setUserEmail] = useState(user?.email || "");
  const [managerName, setManagerName] = useState({});

  const [selectedLogo, setSelectedLogo] = useState(KAI);

  useEffect(() => {
    if (user) {
      setUserName(user.name || "");
      setUserEmail(user.email || "");
      setSignatureImage("https://back-itonline.algosaibi.sa/" + user?.profileImage || null);
      setDirectManager(user?.manager);
      setManagerName(user?.manager?.name);
    }
  }, [user]);

  const currentYear = new Date().getFullYear();

  // Get authenticated user when component loads
  useEffect(() => {
    dispatch(getMe());
    if (isError) navigate("/");
  }, [dispatch, isError, navigate]);

  useEffect(() => {
    if (initialData) {
      console.log("initialData", initialData);

      if (nameRef.current) nameRef.current.value = initialData?.name || "";
      if (emailRef.current) emailRef.current.value = initialData?.email || "";
      if (managerNameRef.current)
        managerNameRef.current.value = initialData?.managerName || "";
      if (companyRef.current)
        companyRef.current.value = initialData?.company || "";
      if (managerRef.current)
        managerRef.current.value = initialData?.manager1 || "";
      if (selectRef.current)
        selectRef.current.value = initialData?.assetClass || "";
      if (assetRef.current)
        assetRef.current.value = initialData?.assetNumber || "";
      if (departmentRef.current)
        departmentRef.current.value = initialData?.department || "";
      if (locationRef.current)
        locationRef.current.value = initialData?.location || "";
      if (centreNameRef.current)
        centreNameRef.current.value = initialData?.centreName || "";
      if (centreNumberRef.current)
        centreNumberRef.current.value = initialData?.centreNumber || "";
      if (potentialUserRef.current)
        potentialUserRef.current.value = initialData?.potentialUser || "";
      if (employeeUNRef.current)
        employeeUNRef.current.value = initialData?.employeeNumber || "";
      if (assetDescriptionRef.current)
        assetDescriptionRef.current.value = initialData?.assetDescription || "";
      if (specificationRef.current)
        specificationRef.current.value = initialData?.specification || "";

      setInput1(
        initialData.quantityRequested
          ? Math.floor(initialData.quantityRequested / 2)
          : 0
      );
      setInput2(
        initialData.quantityRequested
          ? Math.ceil(initialData.quantityRequested / 2)
          : 0
      );

      if (TotalEstimatedcostRef.current)
        TotalEstimatedcostRef.current.value =
          initialData.totalEstimatedCost || "";
      if (additionalThoughtRef.current)
        additionalThoughtRef.current.value =
          initialData.additionalThought || "";
      if (BudgetRef.current)
        BudgetRef.current.value = initialData.budgetReference || "";
      if (financialYearRef.current)
        financialYearRef.current.value =
          initialData.financialYear || currentYear;
      if (purposeOfRequestRef.current)
        purposeOfRequestRef.current.value = initialData.purposeOfRequest || "";
      if (assetBenefitsRef.current)
        assetBenefitsRef.current.value = initialData.assetBenefits || "";
      if (JustificationRef.current)
        JustificationRef.current.value = initialData.justification || "";
    }
  }, [initialData]);

  const handleSignatureUpload = async (e) => {
    const file = e.target.files[0];
    const filepath = await uploadToServer(file);
    setSignatureImage(`https://back-itonline.algosaibi.sa/${filepath}`);
  };

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get("https://back-itonline.algosaibi.sa/managers", {
          withCredentials: true,
        });
        setManagers(response.data);
        const result = await axios.get("https://back-itonline.algosaibi.sa/companies", {
          withCredentials: true,
        });
        setCompanies(result.data.companies);
        const data = await axios.get("https://back-itonline.algosaibi.sa/departments", {
          withCredentials: true,
        });
        setDepartments(data.data.departments);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, []);

  useEffect(() => {
    const uniqueItems = new Set();
    departments.forEach(department => {
      if (department.items && Array.isArray(department.items)) {
        department.items.forEach(item => uniqueItems.add(item));
      }
    });
    setAllItems(Array.from(uniqueItems));
  }, [departments]);

  // const handleManagerSelection = (e) => {
  //   const selectedOptions = Array.from(
  //     e.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setSelectedManagers(selectedOptions);
  // };

  const saveSignature = () => {
    if (!sigPad1.current.isEmpty()) {
      const signature = sigPad1.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureImage(signature);
    } else {
      alert("Signature canvas is empty.");
    }
  };

  const clearSignature = () => {
    sigPad1.current.clear();
    setSignatureImage(null);
  };

  const saveDraft = async () => {
    const formData = collectFormData();

    if (!user || !user.uuid) {
      console.error("User is not authenticated");
      alert("Please log in to save a draft");
      return;
    }

    const draftData = {
      user_uuid: user.uuid, // Keep this for reference if needed
      form_data: formData,
      form_type: "AFE_Form",
      user_email: user.email,
    };

    try {
      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/drafts",
        draftData,
        {
          withCredentials: true,
        }
      );
      alert("Draft saved successfully");
    } catch (error) {
      console.error(
        "Error saving draft:",
        error.response?.data || error.message
      );
      alert(
        `Error saving draft: ${error.response?.data?.message || error.message
        }. Please try again.`
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = collectFormData();

    try {
      if (!quotientOne && !quotientTwo && !quotientThree) {
        alert("Please upload at least one file.");
        return;
      }

      if (!filteredDepartmentList) {
        alert("Please select your department");
        return;
      }
      const getManager = remainingManagers.map((m) => (m ? m?.name : ""));

      // Check if any item is undefined in getManager or if the length is not 6
      if (
        getManager.some((name) => name === undefined) ||
        getManager.length !== 6
      ) {
        alert("Please select your direct manager");
        return;
      }

      const justify = JustificationRef.current.value;
      const signatureImage = formData.signature || null;

      const pdfBytes = await modifyPdf(
        formData,
        getManager,
        input1,
        input2,
        selectedLogo,
        formData.signature,
        justify
      );
      remainingManagers.unshift(directManager);

      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const formDataForBackendPdf = new FormData();
      formDataForBackendPdf.append("file", pdfBlob, "AFE-Form.pdf");
      formDataForBackendPdf.append("form_data", JSON.stringify(formData));
      formDataForBackendPdf.append("form_type", "AFE_Form");
      formDataForBackendPdf.append("user_id", user?.id || user?.uuid);
      formDataForBackendPdf.append("user_email", formData.email);
      formDataForBackendPdf.append("quotientOne", quotientOne);
      formDataForBackendPdf.append("quotientTwo", quotientTwo);
      formDataForBackendPdf.append("quotientThree", quotientThree);
      formDataForBackendPdf.append(
        "managers",
        JSON.stringify(remainingManagers.map((m) => m.uuid))
      );

      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/forms",
        formDataForBackendPdf,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      download(pdfBytes, "Form_AFE.pdf", "application/pdf");
      alert("Form submitted successfully");
      //navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting form:", error);
      // alert(`Error submitting form: ${error.message}. Please try again.`);
    }
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = collectFormData();
  //   console.log("Form Data:", formData);

  //   try {
  //     // Simplified manager data for testing
  //     const testManagers = ['Manager 1', 'Manager 2', 'Manager 3', 'Manager 4', 'Manager 5', 'Manager 6'];

  //     // Use some dummy values for input1 and input2
  //     const testInput1 = input1;
  //     const testInput2 = input2;
  //     const testSignature = formData.signature;

  //     // Make sure selectedLogo is defined, or use a default logo URL
  //     const testLogoUrl = selectedLogo || AGS;

  //     // Call modifyPdf with test data
  //     const pdfBytes = await modifyPdf(formData, testManagers, testInput1, testInput2, testLogoUrl, testSignature);

  //     // Download the generated PDF
  //     download(pdfBytes, "Test_AFE_Form.pdf", "application/pdf");

  //     console.log("PDF generated and downloaded successfully");
  //   } catch (error) {
  //     console.error("Error in modifyPdf function:", error);
  //     alert(`Error generating PDF: ${error.message}. Please check the console for more details.`);
  //   }
  // };

  const collectFormData = () => {
    return {
      name: userName,
      email: userEmail,
      managerName: managerName,
      company: selectedCompany?.name || "",
      assetClass: selectedAssetClass || selectRef.current?.value || "", // Add this line
      assetNumber: assetRef.current?.value || "",
      department: departmentRef.current?.value || "",
      location: selectedLocation || locationRef.current?.value || "", // Add this line
      centreName: centreNameRef.current?.value || "",
      centreNumber: centreNumberRef.current?.value || "",
      potentialUser: potentialUserRef.current?.value || "",
      employeeNumber: employeeUNRef.current?.value || "",
      assetDescription: assetDescriptionRef.current?.value || "",
      specification: specificationRef.current?.value || "",
      quantityRequested: input1 + input2,
      totalEstimatedCost: updateTotalEstimatedCost() || "",
      additionalThought: additionalThoughtRef.current?.value || "",
      budgetReference: BudgetRef.current?.value || "",
      justification: JustificationRef.current?.value || "",
      financialYear: financialYearRef.current?.value || "",
      purposeOfRequest: purposeOfRequestRef.current?.value || "",
      assetBenefits: assetBenefitsRef.current?.value || "",
      signature: signatureImage || "",
    };
  };

  const uploadToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/api/upload",
        formData
      );
      console.log("File uploaded successfully:", response.data);
      return response.data.file;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleQuotientOneUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const filePath = await uploadToServer(file);
      setQuotientOne(filePath);
    }
  };

  const handleQuotientTwoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const filePath = await uploadToServer(file);
      setQuotientTwo(filePath);
    }
  };

  const handleQuotientThreeUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const filePath = await uploadToServer(file);
      setQuotientThree(filePath);
    }
  };
  const [filteredDepartmentList, setFilteredDepartmentList] = useState([]);

  const handleCompanyChange = async () => {
    const selectedUuid = companyRef.current.value;

    if (selectedUuid) {
      try {
        const response = await axios.get(
          `https://back-itonline.algosaibi.sa/companies/${selectedUuid}`
        );
        setSelectedCompany(response.data.company);
        const companyName = response.data.company.name;

        switch (companyName) {
          case "AGS":
            setFilteredDepartmentList(AGSdepartmentsList);
            break;
          case "KAI":
            setFilteredDepartmentList(KAIdepartmentsList);
            break;
          case "SICAST":
            setFilteredDepartmentList(SICASTdepartmentsList);
            break;
          case "Fushi":
            setFilteredDepartmentList(FushidepartmentsList);
            break;
          // Add other cases as needed
          default:
            setFilteredDepartmentList([]);
        }

        const remaining = response.data?.company?.users.slice(0, 5);
        const updatedRemaining = [remainingManagers[0], ...remaining];

        setRemainingManagers(updatedRemaining);

        // Update the logo
        if (response.data.company.profileImage) {
          setSelectedLogo(
            `https://back-itonline.algosaibi.sa/${response.data.company.profileImage}`
          );
        } else {
          setSelectedLogo(KAI); // Set to default logo if no company-specific logo
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    } else {
      setSelectedCompany(null);
      setFilteredDepartmentList([]);
      setSelectedLogo(KAI); // Reset to default logo when no company is selected
    }
  };

  console.log(remainingManagers);

  const handleManagerChange = (e) => {
    const managerUuid = e.target.value;
    const manager = selectedCompany?.users?.find(
      (user) => user.uuid === managerUuid
    );
    setDirectManager(manager);
    setManagerName(manager.name);
    const remaining = selectedCompany?.users
      .filter((user) => user.uuid !== managerUuid)
      .slice(0, 6);
    setRemainingManagers(remaining);
  };

  const [showPreview, setShowPreview] = useState(false);
  const [previewPdfUrl, setPreviewPdfUrl] = useState(null);

  const handlePreview = async () => {
    if (!quotientOne && !quotientTwo && !quotientThree) {
      alert("Please upload at least one file.");
      return;
    }

    if (!filteredDepartmentList) {
      alert("Please select your department");
      return;
    }
    const getManager = remainingManagers.map((m) => m?.name);

    if (getManager.length != 6) {
      alert("Please select Your direct manager");
      return;
    }
    const formData = collectFormData();

    if (!formData.signature || !signatureImage) {
      alert("Please provide your signature in your profile.");
      return;
    }
    const justify = JustificationRef.current.value;

    try {
      const pdfBytes = await modifyPdf(
        formData,
        getManager,
        input1,
        input2,
        selectedLogo,
        formData.signature,
        justify
      );
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPreviewPdfUrl(pdfUrl);
      setShowPreview(true);
    } catch (error) {
      console.error("Error generating preview:", error);
      alert("Error generating preview. Please try again.");
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    if (previewPdfUrl) {
      URL.revokeObjectURL(previewPdfUrl);
    }
  };

  const findManagerForAssetClass = (assetClass) => {
    for (const dept of departments) {
      if (dept.items && dept.items.includes(assetClass)) {
        return dept.manager;
      }
    }
    return null;
  };
  const handleAssetClassSelect = (assetClass) => {
    setSelectedAssetClass(assetClass);
    const manager = findManagerForAssetClass(assetClass);
    if (manager) {
      setRemainingManagers([manager, ...remainingManagers.slice(1)]);
    } else {
      // Reset manager if no matching department found
      setRemainingManagers([{ name: '' }, ...remainingManagers.slice(1)]);
    }
    setSearchTermAssetClass("");
    if (assetRef.current) {
      assetRef.current.value = assetClass;
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="form-afe">
        <div className="header">
          <div className="company-info">
            <img
              src={KAI}
              alt="Khalifa Algosaibi Investment Co. Logo"
              className="logo kai-logo"
              style={{ marginLeft: "1em" }}
            />
            <h1
              style={{
                marginLeft: "0.8em",
                fontSize: "1.2em",
                marginBottom: "5px",
              }}
            >
              <strong>Khalifa Algosaibi Investment Co.</strong>
            </h1>
            <p style={{ marginLeft: "1em" }}>FIXED ASSET REQUISITION FORM</p>
          </div>
          <div className="table-logo-container">
            <div className="logo-container">
              <img
                src={selectedLogo}
                alt="Company Logo"
                className="table-logo"
              />
            </div>
            <table className="asset-details-table">
              <tbody>
                <tr>
                  <th>SBU Name</th>
                  <td>
                    <Form.Select
                      ref={companyRef}
                      onChange={handleCompanyChange}
                    >
                      <option value="">Choose...</option>
                      {companies.map((company) => (
                        <option key={company.uuid} value={company.uuid}>
                          {company.name}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>

                <tr>
                  <th>Asset Class</th>
                  <td className="position-relative">
                    <div className="dropdown d-grid gap-2">
                      <button
                        className="btn btn-light dropdown-toggle"
                        type="button"
                        id="dropdownAssetClassButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {selectedAssetClass || "Choose..."}
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownAssetClassButton"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          minWidth: "200px",
                          maxHeight: "300px",
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <input
                            type="text"
                            ref={selectRef}
                            className="form-control mx-3 my-2 w-auto"
                            placeholder="Type to filter..."
                            onChange={(e) => setSearchTermAssetClass(e.target.value)}
                            value={searchTermAssetClass}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </li>
                        {filteredAssetClasses.map((assetClass, index) => (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAssetClassSelect(assetClass);
                              }}
                            >
                              {assetClass}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Asset No. (MS Dynamics)</th>
                  <td>
                    <Form.Control type="text" disabled />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="table-container">
          <table className="form-table">
            <tbody>
              <tr>
                <th>Department</th>
                <td className="position-relative">
                  <div className="dropdown d-grid gap-2">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="dropdownDepartmentButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedDepartmentList || "Choose..."}
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownDepartmentButton"
                      style={{
                        position: "absolute",
                        willChange: "transform",
                        minWidth: "200px",
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <li>
                        <input
                          type="text"
                          ref={selectRef}
                          className="form-control mx-3 my-2 w-auto"
                          placeholder="Type to filter..."
                          onChange={(e) =>
                            setSearchTermDepartmentList(e.target.value)
                          }
                          value={searchTermDepartmentList}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </li>
                      {filteredDepartmentList
                        .filter(department =>
                          department.toLowerCase().includes(searchTermDepartmentList.toLowerCase())
                        )
                        .map((department, index) => (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedDepartmentList(department);
                                setSearchTermDepartmentList("");
                                if (departmentRef.current) {
                                  departmentRef.current.value = department;
                                }
                              }}
                            >
                              {department}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <input type="hidden" ref={departmentRef} name="department" />
                </td>
                <th>Location</th>
                <td className="position-relative ">
                  <div className="dropdown d-grid gap-2">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedLocation || "Choose..."}
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton"
                      style={{
                        position: "absolute",
                        willChange: "transform",
                        minWidth: "200px",
                      }}
                    >
                      <li>
                        <input
                          type="text"
                          ref={locationRef}
                          className="form-control mx-3 my-2 w-auto"
                          placeholder="Type to filter..."
                          onChange={(e) => setSearchTerm(e.target.value)}
                          value={searchTerm}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </li>
                      {filteredLocations.map((location, index) => (
                        <li key={index}>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedLocation(location);
                              setSearchTerm("");
                              if (locationRef.current) {
                                locationRef.current.value = location;
                              }
                            }}
                          >
                            {location}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Profit / Cost Centre Name</th>
                <td>
                  <Form.Control type="text" ref={centreNameRef} />
                </td>
                <th>Profit / Cost Centre Number</th>
                <td>
                  <Form.Control type="text" ref={centreNumberRef} />
                </td>
              </tr>
              <tr>
                <th rowSpan="2">Potential User (if in use by an individual)</th>
                <td className="white-text">Employee Name</td>
                <td colSpan="2">
                  <Form.Control
                    type="text"
                    ref={potentialUserRef}
                    placeholder="Employee name to be entered if the asset is specifically used by an individual employee"
                  />
                </td>
              </tr>
              <tr>
                <td className="white-text">Employee ID</td>
                <td colSpan="2">
                  <Form.Control
                    type="text"
                    ref={employeeUNRef}
                    placeholder="Employee ID to be entered for above employee"
                  />
                </td>
              </tr>
              <tr>
                <th>Asset Description</th>
                <td colSpan="3">
                  <Form.Control type="text" ref={assetDescriptionRef} />
                </td>
              </tr>
              <tr>
                <th>Specification / Details of Requested Asset</th>
                <td colSpan="3">
                  <Form.Control type="text" ref={specificationRef} />
                </td>
              </tr>
              <tr>
                <th rowSpan="2">Quantity Requested</th>
                <th>Additional</th>
                <th>Replacement</th>
                <th>Total</th>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    type="number"
                    value={input1}
                    onChange={(e) => setInput1(parseInt(e.target.value, 10))}
                    placeholder="Mention numeric number here"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={input2}
                    onChange={(e) => setInput2(parseInt(e.target.value, 10))}
                    placeholder="Mention numeric number here"
                  />
                </td>
                <td>
                  <Form.Control type="text" value={input1 + input2} disabled />
                </td>
              </tr>


              <tr>
                <th>Quotations</th>
                <th colSpan={3}>
                  <Form.Group controlId="formQuotients" className="mb-4">
                    <div className="d-flex gap-2">
                      {["One", "Two", "Three"].map((num) => (
                        <Form.Group
                          key={`formQuotient${num}`}
                          controlId={`formQuotient${num}`}
                          className="mb-4"
                        >
                          <Form.Label>Quotient {num}</Form.Label>
                          <Form.Control
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              if (num === "One") handleQuotientOneUpload(e);
                              else if (num === "Two") handleQuotientTwoUpload(e);
                              else handleQuotientThreeUpload(e);
                            }}
                          />
                        </Form.Group>
                      ))}
                    </div>
                  </Form.Group>
                </th>
              </tr>


              <tr>
                <th>Total Estimated Cost of Asset Requested</th>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="custom-dropdown me-2">
                      <select
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                        className="currency-select"
                      >
                        {currencies.map((currency) => (
                          <option key={currency} value={currency}>
                            {currency}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Form.Control
                      type="text"
                      value={amount}
                      onChange={handleCostChange}
                      placeholder="980.0"
                    />
                  </div>
                </td>
                <td colSpan="2">
                  Kindly attach evidence of estimate; quotations, offers, price list, etc.
                </td>
              </tr>
              <tr>
                <th>Budget / Post budget approval reference (if applicable)</th>
                <td>
                  <Form.Control type="text" ref={BudgetRef} placeholder="NA" />
                </td>
                <td>Financial year</td>
                <td>
                  <Form.Control
                    type="text"
                    value={currentYear}
                    ref={financialYearRef}
                    readOnly
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th>Justification (in case of unbudgeted asset)</th>
                <td colSpan="3">
                  <Form.Control
                    type="text"
                    placeholder=""
                    ref={JustificationRef}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  Purpose and circumstances giving rise to the need of requested
                  asset and its quantity
                </th>
                <td colSpan="3">
                  <Form.Control
                    type="text"
                    ref={purposeOfRequestRef}
                    placeholder="Brief note on why the asset is required"
                  />
                </td>
              </tr>
              <tr>
                <th>Asset Utilization and Benefits</th>
                <td colSpan="3">
                  <Form.Control
                    type="text"
                    ref={assetBenefitsRef}
                    placeholder="Brief note on who will use it and its benefit to the user and company"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4 ">
          <table className="form-table">
            <thead>
              <tr>
                <th>Requesting person (if any)</th>
                <th>Department Head of SBU</th>
                <th>Function Head of SBU</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Control type="text" value={user?.name} readOnly />
                </td>
                <td>
                  {/* <Form.Select
                    defaultValue={
                      directManager ? directManager.name : "Choose Manager"
                    }
                    ref={managerRef}
                    onChange={handleManagerChange}
                  >
                    {selectedCompany?.users?.map((user) => (
                      <option value={user.uuid}>{user.name}</option>
                    ))}
                  </Form.Select> */}

                  <Form.Control
                    type="text"
                    value={directManager?.name}
                    readOnly
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[0]?.name || ''}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={signatureImage}
                    alt="Preview"
                    style={{ maxWidth: "170px" }}
                  />
                </td>
                <td>
                  <SignatureField />
                </td>
                <td>
                  <SignatureField />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <div className="mt-4">
          <h5 className="mb-3">VERIFICATION BY FINANCE</h5>
          <table className="form-table">
            <tbody>
              <tr>
                {/* <th></th> 
                <th style={{ width: "25%" }}>Total amount</th>
                <th style={{ width: "30%" }}>Supplier Name</th>
                <th style={{ width: "50%" }}>Remarks (if any)</th>
              </tr>
              <tr>
                <td>
                  <Form.Control type="text" placeholder="Budgeted" />
                </td>
                <td rowSpan="5">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Finalized Supplier name to be mentioned. Incase of multiple suppliers, mention 'Multiple suppliers'"
                  />
                </td>
                <td rowSpan="5">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Anything relevant for the approvers to know or anything required to be highlighted to be mentioned here"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control type="text" placeholder="Already utilized" />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="Remaining balance of budget"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control type="text" placeholder="Requested" />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    type="text"
                    placeholder="(Shortfall) / Surplus (%)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}

        <div className="mt-4 ">
          <h5 className="mb-3 ">Approval & Validation</h5>
          <table className="form-table">
            <thead>
              <tr>
                <th>Group SC Manager</th>
                <th>SBU Finance Head</th>
                <th>SBU Head / CEO / GM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[1]?.name}
                    readOnly
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[2]?.name}
                    readOnly
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[3]?.name}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SignatureField />
                </td>
                <td>
                  <SignatureField />
                </td>
                <td>
                  <SignatureField />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4 ">
          <h5 className="mb-3 ">Approval & Validation</h5>
          <table className="form-table">
            <thead>
              <tr>
                <th>Group HoF</th>
                <th>Group CFO</th>
                <th>Group CEO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Control
                    type="text"
                    defaultValue="Empty for now"
                    readOnly
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[4]?.name}
                    readOnly
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={remainingManagers[5]?.name}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <SignatureField />
                </td>
                <td>
                  <SignatureField />
                </td>
                <td>
                  <SignatureField />
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
                <td>
                  <Form.Control type="date" readOnly />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Row className="justify-content-md-center mt-4">
        <Col lg="6">

          {/* 
          <Card className="mb-3">
            <Card.Header>Signature</Card.Header>
            <Card.Body>
              <SignatureCanvas
                ref={sigPad1}
                canvasProps={{
                  width: 470,
                  height: 200,
                  className: "sigCanvas",
                }}
              />
              <Button variant="primary" onClick={saveSignature}>
                Save Drawn Signature
              </Button>
              <Button
                variant="secondary"
                onClick={clearSignature}
                className="ms-2"
              >
                Clear
              </Button>
              <Form.Group controlId="formSignatureUpload" className="mt-3">
                <Form.Label>Or Upload Signature (PNG or JPEG)</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/png, image/jpeg, application/pdf"
                  onChange={handleSignatureUpload}
                />
                {signatureImage && (
                  <div>
                    {signatureImage.endsWith(".pdf") ? (
                      <p>PDF file selected: {signatureImage}</p>
                    ) : (
                      <img
                        src={signatureImage}
                        alt="Preview"
                        style={{ maxWidth: "250px" }}
                      />
                    )}
                  </div>
                )}
              </Form.Group>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>

      <div className="d-flex justify-content-center mt-4">
        <Button variant="secondary" onClick={saveDraft} className="me-2">
          Save as Draft
        </Button>
        {/* <Button variant="primary" type="submit" >
          Submit Form
        </Button> */}
        <Button onClick={handlePreview}>Submit</Button>
        <Modal
          show={showPreview}
          onHide={handleClosePreview}
          size="lg"
          className="preview-modal custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Form Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>PDF Preview</h4>
            <iframe
              src={previewPdfUrl}
              width="100%"
              height="500px"
              title="PDF Preview"
            />

            <h4>Signature</h4>
            <img
              src={signatureImage}
              alt="Signature"
              className="preview-signature"
            />

            <h4>Quotations</h4>
            {quotientOne && <p>Quotation 1: {quotientOne}</p>}
            {quotientTwo && <p>Quotation 2: {quotientTwo}</p>}
            {quotientThree && <p>Quotation 3: {quotientThree}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePreview}>
              Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Form>
  );
};

export default FormAFE;
