import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./CSS/FormEditUser.css";
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice";

const FormEditUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [role, setRole] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [profileImage, setProfileImage] = useState(null); // Change to handle file input
  const [company, setCompany] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [field5, setField5] = useState("");
  const [msg, setMsg] = useState("");
  const [previewImage, setPreviewImage] = useState(""); // For previewing the uploaded image
  const navigate = useNavigate();
  const [superManager, setSuperManager] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    if (selectedData?.includes(item.uuid)) {
      const data = selectedData.filter((id) => id != item.uuid);
      setSelectedData(data);
    } else {
      setSelectedData([item.uuid]);
    }
  };

  const getManagerNames = (ids) => {
    return ids
      ?.map((id) => {
        const manager = managers?.find((m) => m.uuid === id);
        return manager ? manager.name : null;
      })
      .filter(Boolean)
      .join(", ");
  };

  const dispatch = useDispatch();

  const { managers } = useSelector((state) => state.auth);

  // Handle user update
  const addNewUser = async (e) => {
    e.preventDefault();

    if (!selectedData.length) {
      alert("Please select a manager");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confPassword", confPassword);
    formData.append("role", role);
    formData.append("company", company);
    formData.append("field1", field1);
    formData.append("field2", field2);
    formData.append("field3", field3);
    formData.append("field4", field4);
    formData.append("field5", field5);
    if (role == "user") {
      formData.append("manager", selectedData[0]);
    }

    try {
      await axios.post(`https://back-itonline.algosaibi.sa/users`, {
        name,
        email,
        password,
        confPassword,
        role,
        company,
        field1,
        field2,
        field3,
        field4,
        field5,
        manager: selectedData[0],
      });
      navigate("/users");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  return (
    <div className="form-container">
      <h1 className="form-title">Add New User</h1>
      <form onSubmit={addNewUser}>
        <p className="text-center text-danger">{msg}</p>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        <div className="form-group">
          <label>Role</label>
          <select
            className="form-control"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="super-manager">Super Manager</option>
            <option value="manager">Manager</option>
          </select>
        </div>

        {role === "user" && (
          <div>
            <div className="mb-3" ref={dropdownRef}>
              <label>Select Manager(s)</label>
              <br />
              <div className="dropdown w-100">
                <button
                  className="form-control dropdown-toggle rounded-xl border border-skylight text-charcoal"
                  type="button"
                  onClick={handleToggleDropdown}
                  aria-expanded={isOpen}
                >
                  {getManagerNames(selectedData) || "Choose an option"}{" "}
                </button>
                <ul
                  className={`dropdown-menu ${
                    isOpen ? "show w-100" : ""
                  } no-bullets`}
                >
                  {managers?.map((item, index) =>
                    item.role == "manager" ? (
                      <li key={index} onClick={() => handleItemClick(item)}>
                        <div
                          className={`dropdown-item ${
                            selectedData?.includes(item?.uuid) ? "active" : ""
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          {item?.name}
                        </div>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="form-group">
          <label>Company</label>
          <input
            type="text"
            className="form-control"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company"
          />
        </div>

        <div className="form-group">
          <label>Field1</label>
          <input
            type="text"
            className="form-control"
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            placeholder="Custom Field 1"
          />
        </div>

        <div className="form-group">
          <label>Field2</label>
          <input
            type="text"
            className="form-control"
            value={field2}
            onChange={(e) => setField2(e.target.value)}
            placeholder="Custom Field 2"
          />
        </div>

        <div className="form-group">
          <label>Field3</label>
          <input
            type="text"
            className="form-control"
            value={field3}
            onChange={(e) => setField3(e.target.value)}
            placeholder="Custom Field 3"
          />
        </div>

        <div className="form-group">
          <label>Field4</label>
          <input
            type="text"
            className="form-control"
            value={field4}
            onChange={(e) => setField4(e.target.value)}
            placeholder="Custom Field 4"
          />
        </div>

        <div className="form-group">
          <label>Field5</label>
          <input
            type="text"
            className="form-control"
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            placeholder="Custom Field 5"
          />
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEditUser;
