import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    try {
      const response = await axios.get("https://back-itonline.algosaibi.sa/departments");
      if (response.data.success) {
        setDepartments(response.data.departments);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const deleteDepartment = async (departmentId) => {
    try {
      await axios.delete(`https://back-itonline.algosaibi.sa/department/${departmentId}`);
      getDepartments(); // Refresh the department list
    } catch (error) {
      console.error("Error deleting department:", error);
    }
  };

  return (
    <div>
      <h1 className="title">Departments</h1>
      <h2 className="subtitle">List of Departments</h2>
      <Link to="/department/edit/0" className="button is-primary mb-2">
        Add New
      </Link>
      {departments.length === 0 ? (
        <p>No department found.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Items</th>
              <th>Function Head of SBU</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {departments.map((item, index) => (
              <tr key={item.uuid}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.items.join(", ")}</td>{" "}
                {/* Display items as a comma-separated list */}
                <td>{item?.manager?.name}</td>
                <td>
                  <Link to={`/department/edit/${item.uuid}`}>
                    <Button
                      variant="info"
                      size="sm"
                      style={{ marginRight: 10 }}
                    >
                      Edit
                    </Button>
                  </Link>
                  <Button
                    onClick={() => deleteDepartment(item.uuid)}
                    variant="danger"
                    size="sm"
                  >
                    Delete Department
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default DepartmentList;
