import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./CSS/FormEditUser.css"; // You can reuse styles or create new ones as needed
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice"; // Ensure this is adapted to your needs

const DepartmentForm = () => {
  const [name, setName] = useState("");
  const [newItem, setNewItem] = useState(""); // Input for new item
  const [items, setItems] = useState([]); // Array for multiple items
  const [msg, setMsg] = useState("");
  const [managersData, setManagersData] = useState([]);
  const [managersDataArray, setManagersDataArray] = useState([]);
  const { id: departmentId } = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setManagersDataArray([item]);
    setIsOpen(false);
  };

  const dispatch = useDispatch();
  const { managers } = useSelector((state) => state.auth);

  useEffect(() => {
    const getDepartmentById = async () => {
      try {
        const response = await axios.get(
          `https://back-itonline.algosaibi.sa/departments/${departmentId}`
        );
        const data = response.data.department;
        setManagersDataArray([data.manager]);
        setName(data.name);
        setItems(data.items || []);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.message);
        }
      }
    };

    if (departmentId !== "0") {
      getDepartmentById();
    }
  }, [departmentId]);

  const updateDepartment = async (e) => {
    e.preventDefault();
    const managerIds = managersDataArray.map((manager) => manager.uuid);

    if (!name || managerIds.length === 0 || items.length === 0) {
      alert("Please fill in all fields");
      return;
    }

    const data = {
      name,
      items,
      managerId: managerIds[0],
    };

    try {
      if (departmentId !== "0") {
        const response = await axios.put(
          `https://back-itonline.algosaibi.sa/department/${departmentId}`,
          data
        );
        if (response.data.success) {
          navigate("/departments");
        }
      } else {
        const response = await axios.post(
          "https://back-itonline.algosaibi.sa/department",
          data
        );
        if (response.data.success) {
          navigate("/departments");
        }
      }
    } catch (error) {
      console.error("Error saving department:", error);
    }
  };

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  // Function to add a new item to the items array
  const handleAddItem = () => {
    if (newItem.trim() === "") return; // Prevent adding empty items
    setItems([...items, newItem]);
    setNewItem(""); // Clear the input after adding
  };

  // Function to remove an item from the items array
  const handleRemoveItem = (itemToRemove) => {
    setItems(items.filter((item) => item !== itemToRemove));
  };

  return (
    <div className="form-container">
      <h1 className="form-title">
        {departmentId !== "0" ? "Edit Department" : "Add Department"}
      </h1>
      <form onSubmit={updateDepartment}>
        <p className="text-center text-danger">{msg}</p>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Responsible authority Name"
          />
        </div>

        <div className="form-group">
          <label>Items</label>
          <input
            type="text"
            className="form-control "
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Add Item"
          />
          <span
            type="button"
            className="btn btn-secondary mt-2"
            onClick={handleAddItem}
          >
            Add
          </span>
          <ul className="list-group mt-2">
            {items.map((item, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                {item}
                <span
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveItem(item)}
                >
                  Remove
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-3" ref={dropdownRef}>
          <label>Select Function Head of SBU</label>
          <br />
          <div className="dropdown w-100">
            <button
              className="form-control dropdown-toggle rounded-xl border border-skylight text-charcoal overflow-auto"
              type="button"
              onClick={handleToggleDropdown}
              aria-expanded={isOpen}
            >
              {managersDataArray?.map((item, index) => (
                <span key={index}>
                  {item.name}
                  {index < managersDataArray.length - 1 ? ", " : ""}
                </span>
              ))}
            </button>
            <ul className={`dropdown-menu ${isOpen ? "show w-100" : ""}`}>
              {managers?.map(
                (item, index) =>
                  item.role === "manager" && (
                    <li key={index} onClick={() => handleItemClick(item)}>
                      <div
                        className={`dropdown-item ${
                          managersData.includes(item.uuid) ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </div>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            {departmentId !== "0" ? "Update Department" : "Add Department"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DepartmentForm;
