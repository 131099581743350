// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./CSS/FormEditUser.css"; // Reuse the same CSS as FormEditUser
// import { useDispatch, useSelector } from "react-redux";
// import { getManagers } from "../features/authSlice";
// import { useRef } from 'react';
// import SignatureCanvas from 'react-signature-canvas';
// import { Nav, Tab } from 'react-bootstrap';


// const ProfilePage = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confPassword, setConfPassword] = useState("");
//   const [profileImage, setProfileImage] = useState(null); // Change to handle file input
//   const [company, setCompany] = useState("");
//   const [field1, setField1] = useState("");
//   const [field2, setField2] = useState("");
//   const [field3, setField3] = useState("");
//   const [field4, setField4] = useState("");
//   const [field5, setField5] = useState("");
//   const [msg, setMsg] = useState("");
//   const [previewImage, setPreviewImage] = useState("");
//   const [userManagers, setUserManagers] = useState([]);
//   const [signatureType, setSignatureType] = useState('upload'); // 'upload' or 'draw'
//   const sigPad = useRef(null);

//   const { managers } = useSelector((state) => state.auth);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getManagers());
//     const getProfile = async () => {
//       try {
//         const response = await axios.get("http://172.16.1.139:5000/profile");
//         const data = response.data;
//         setName(data.name);
//         setEmail(data.email);
//         setCompany(data.company);
//         setField1(data.field1);
//         setField2(data.field2);
//         setField3(data.field3);
//         setField4(data.field4);
//         setField5(data.field5);
//         if (data.profileImage) {
//           setPreviewImage(`http://172.16.1.139:5000/${data.profileImage}`);
//         }

//         setUserManagers(data.managers.split(","));
//       } catch (error) {
//         if (error.response) {
//           setMsg(error.response.data.msg);
//         }
//       }
//     };
//     getProfile();
//   }, [dispatch]);

//   // Handle profile update
//   const updateProfile = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("name", name);
//     formData.append("email", email);
//     formData.append("password", password);
//     formData.append("confPassword", confPassword);
//     formData.append("profileImage", profileImage);
//     formData.append("company", company);
//     formData.append("field1", field1);
//     formData.append("field2", field2);
//     formData.append("field3", field3);
//     formData.append("field4", field4);
//     formData.append("field5", field5);

//     try {
//       await axios.patch("http://172.16.1.139:5000/profile", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       alert("Profile updated successfully!"); // Add this line for a success alert
//     } catch (error) {
//       if (error.response) {
//         alert(error.response.data.msg || "Error updating profile. Please try again."); // Add this line for an error alert
//       } else {
//         alert("An error occurred. Please try again."); // Generic error message if response is not available
//       }
//     }
//   };

//   // Handle image preview
//   // const handleImageChange = async (e) => {
//   //   const file = e.target.files[0];
//   //   const filePath = await uploadToServer(file);
//   //   setProfileImage(filePath);
//   //   setPreviewImage(URL.createObjectURL(file)); // Preview the selected image
//   // };

//   const uploadToServer = async (file) => {
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(
//         "http://172.16.1.139:5000/api/upload",
//         formData
//       );
//       console.log("File uploaded successfully:", response.data);

//       return response.data.file;
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   const handleSignatureTypeChange = (type) => {
//     setSignatureType(type);
//     setPreviewImage(null);
//     if (type === 'draw' && sigPad.current) {
//       sigPad.current.clear();
//     }
//   };



//   const handleImageChange = async (e) => {
//     let file;
//     let imageUrl;

//     if (signatureType === 'upload') {
//       file = e.target.files[0];
//       imageUrl = URL.createObjectURL(file);
//     } else {
//       // For drawn signature
//       if (sigPad.current.isEmpty()) {
//         alert("Please draw a signature before saving.");
//         return;
//       }
//       const canvas = sigPad.current.getTrimmedCanvas();
//       imageUrl = canvas.toDataURL('image/png');
//       file = await fetch(imageUrl).then(res => res.blob());
//     }

//     try {
//       const filePath = await uploadToServer(file);
//       setProfileImage(filePath);
//       setPreviewImage(imageUrl);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       alert("Error uploading signature. Please try again.");
//     }
//   };

//   const clearSignature = () => {
//     if (sigPad.current) sigPad.current.clear();
//     setProfileImage(null);
//     setPreviewImage(null);
//   };

//   const saveDrawnSignature = () => {
//     if (sigPad.current.isEmpty()) {
//       alert("Please draw a signature before saving.");
//       return;
//     }
//     handleImageChange();
//   };



//   return (
//     <div className="form-container">
//       <h1 className="form-title">Edit Your Profile</h1>
//       <form onSubmit={updateProfile}>
//         <p className="text-center text-danger">{msg}</p>

//         <div className="form-group">
//           <label>Name</label>
//           <input
//             type="text"
//             className="form-control"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             placeholder="Name"
//           />
//         </div>

//         <div className="form-group">
//           <label>Email</label>
//           <input
//             type="email"
//             className="form-control"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Email"
//           />
//         </div>

//         <div className="form-group">
//           <label>Password</label>
//           <input
//             type="password"
//             className="form-control"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="******"
//           />
//         </div>

//         <div className="form-group">
//           <label>Confirm Password</label>
//           <input
//             type="password"
//             className="form-control"
//             value={confPassword}
//             onChange={(e) => setConfPassword(e.target.value)}
//             placeholder="******"
//           />
//         </div>

//         <div className="form-group">
//           <label>Signature</label>
//           <Tab.Container id="signature-tabs" defaultActiveKey="upload">
//             <Nav variant="tabs" className="mb-3">
//               <Nav.Item>
//                 <Nav.Link eventKey="upload" onClick={() => handleSignatureTypeChange('upload')}>Upload Signature</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="draw" onClick={() => handleSignatureTypeChange('draw')}>Draw Signature</Nav.Link>
//               </Nav.Item>
//             </Nav>
//             <Tab.Content>
//               <Tab.Pane eventKey="upload">
//                 <input
//                   type="file"
//                   className="form-control"
//                   onChange={handleImageChange}
//                 />
//               </Tab.Pane>
//               <Tab.Pane eventKey="draw">
//                 <div className="signature-pad-container">
//                   <SignatureCanvas
//                     ref={sigPad}
//                     canvasProps={{
//                       width: 500,
//                       height: 200,
//                       className: 'signature-canvas'
//                     }}
//                   />
//                 </div>
//                 <div className="mt-2">
//                   <button type="button" className="btn btn-primary me-2" onClick={saveDrawnSignature}>Save Signature</button>
//                   <button type="button" className="btn btn-secondary" onClick={clearSignature}>Clear</button>
//                 </div>
//               </Tab.Pane>
//             </Tab.Content>
//           </Tab.Container>
//           {previewImage && (
//             <img
//               src={previewImage}
//               alt="Signature Preview"
//               className="img-preview mt-3"
//             />
//           )}
//         </div>

//         <div className="form-group">
//           <label>Managers</label>
//           {userManagers?.map((manager) => {
//             const foundManager = managers?.find(
//               (m) => m.uuid === manager.trim()
//             );
//             return foundManager ? foundManager.name : null;
//           })
//             .filter((name) => name !== null) // Filter out null values
//             .map((manager, index) => (
//               <input
//                 key={index}
//                 type="text"
//                 className="form-control"
//                 value={manager}
//                 placeholder="Manager"
//                 disabled={true}
//               />
//             ))}
//         </div>

//         <div className="form-group">
//           <label>Company</label>
//           <input
//             type="text"
//             className="form-control"
//             value={company}
//             onChange={(e) => setCompany(e.target.value)}
//             placeholder="Company"
//           />
//         </div>

//         {/*<div className="form-group">
//           <label>Field1</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field1}
//             onChange={(e) => setField1(e.target.value)}
//             placeholder="Custom Field 1"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field2</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field2}
//             onChange={(e) => setField2(e.target.value)}
//             placeholder="Custom Field 2"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field3</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field3}
//             onChange={(e) => setField3(e.target.value)}
//             placeholder="Custom Field 3"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field4</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field4}
//             onChange={(e) => setField4(e.target.value)}
//             placeholder="Custom Field 4"
//           />
//         </div>

//          <div className="form-group">
//           <label>Field5</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field5}
//             onChange={(e) => setField5(e.target.value)}
//             placeholder="Custom Field 5"
//           />
//         </div> */}

//         <div className="form-group text-center">
//           <button type="submit" className="btn btn-primary">
//             Update
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ProfilePage;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import './CSS/FormEditUser.css'; // Import the same CSS file used for FormEditUser

// const ProfilePage = () => {
//   // Setting up state for user profile information
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confPassword, setConfPassword] = useState("");
//   const [profileImage, setProfileImage] = useState("");
//   const [company, setCompany] = useState("");
//   const [field1, setField1] = useState("");
//   const [field2, setField2] = useState("");
//   const [field3, setField3] = useState("");
//   const [field4, setField4] = useState("");
//   const [field5, setField5] = useState("");
//   const [msg, setMsg] = useState("");

//   // Fetch the user's profile when the component loads
//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const response = await axios.get("http://172.16.1.139:5000/profile");
//         const data = response.data;
//         setName(data.name);
//         setEmail(data.email);
//         setProfileImage(data.profileImage);
//         setCompany(data.company);
//         setField1(data.field1);
//         setField2(data.field2);
//         setField3(data.field3);
//         setField4(data.field4);
//         setField5(data.field5);
//       } catch (error) {
//         if (error.response) {
//           setMsg(error.response.data.msg);
//         }
//       }
//     };
//     fetchProfile();
//   }, []);

//   // Handle profile update
//   const updateProfile = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.patch("http://172.16.1.139:5000/profile", {
//         name,
//         email,
//         password,
//         confPassword,
//         profileImage,
//         company,
//         field1,
//         field2,
//         field3,
//         field4,
//         field5,
//       });
//       setMsg("Profile updated successfully");
//     } catch (error) {
//       if (error.response) {
//         setMsg(error.response.data.msg);
//       }
//     }
//   };

//   return (
//     <div>
//       <h1 className="title">Profile</h1>
//       <h2 className="subtitle">Update Your Profile</h2>
//       <div className="card is-shadowless">
//         <div className="card-content">
//           <div className="content">
//             <form onSubmit={updateProfile}>
//               <p className="has-text-centered">{msg}</p>

//               <div className="field">
//                 <label className="label">Name</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     placeholder="Name"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Email</label>
//                 <div className="control">
//                   <input
//                     type="email"
//                     className="input"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Email"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Password</label>
//                 <div className="control">
//                   <input
//                     type="password"
//                     className="input"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     placeholder="******"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Confirm Password</label>
//                 <div className="control">
//                   <input
//                     type="password"
//                     className="input"
//                     value={confPassword}
//                     onChange={(e) => setConfPassword(e.target.value)}
//                     placeholder="******"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Profile Image URL</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={profileImage}
//                     onChange={(e) => setProfileImage(e.target.value)}
//                     placeholder="Profile Image URL"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Company</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={company}
//                     onChange={(e) => setCompany(e.target.value)}
//                     placeholder="Company"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Field1</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={field1}
//                     onChange={(e) => setField1(e.target.value)}
//                     placeholder="Custom Field 1"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Field2</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={field2}
//                     onChange={(e) => setField2(e.target.value)}
//                     placeholder="Custom Field 2"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Field3</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={field3}
//                     onChange={(e) => setField3(e.target.value)}
//                     placeholder="Custom Field 3"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Field4</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={field4}
//                     onChange={(e) => setField4(e.target.value)}
//                     placeholder="Custom Field 4"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <label className="label">Field5</label>
//                 <div className="control">
//                   <input
//                     type="text"
//                     className="input"
//                     value={field5}
//                     onChange={(e) => setField5(e.target.value)}
//                     placeholder="Custom Field 5"
//                   />
//                 </div>
//               </div>

//               <div className="field">
//                 <div className="control">
//                   <button type="submit" className="button is-success">
//                     Update Profile
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProfilePage;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import './CSS/FormEditUser.css'; // Import your custom CSS for additional styling

// const ProfilePage = () => {
//   // Setting up state for user profile information
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confPassword, setConfPassword] = useState("");
//   const [profileImage, setProfileImage] = useState("");
//   const [company, setCompany] = useState("");
//   const [field1, setField1] = useState("");
//   const [field2, setField2] = useState("");
//   const [field3, setField3] = useState("");
//   const [field4, setField4] = useState("");
//   const [field5, setField5] = useState("");
//   const [msg, setMsg] = useState("");

//   // Fetch the user's profile when the component loads
//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const response = await axios.get("http://172.16.1.139:5000/profile");
//         const data = response.data;
//         setName(data.name);
//         setEmail(data.email);
//         setProfileImage(data.profileImage);
//         setCompany(data.company);
//         setField1(data.field1);
//         setField2(data.field2);
//         setField3(data.field3);
//         setField4(data.field4);
//         setField5(data.field5);
//       } catch (error) {
//         if (error.response) {
//           setMsg(error.response.data.msg);
//         }
//       }
//     };
//     fetchProfile();
//   }, []);

//   // Handle profile update
//   const updateProfile = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.patch("http://172.16.1.139:5000/profile", {
//         name,
//         email,
//         password,
//         confPassword,
//         profileImage,
//         company,
//         field1,
//         field2,
//         field3,
//         field4,
//         field5,
//       });
//       setMsg("Profile updated successfully");
//     } catch (error) {
//       if (error.response) {
//         setMsg(error.response.data.msg);
//       }
//     }
//   };

//   return (
//     <div className="form-container">
//       <h1 className="form-title">Update Your Profile</h1>
//       <form onSubmit={updateProfile}>
//         <p className="text-center text-danger">{msg}</p>

//         <div className="form-group">
//           <label>Name</label>
//           <input
//             type="text"
//             className="form-control"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             placeholder="Name"
//           />
//         </div>

//         <div className="form-group">
//           <label>Email</label>
//           <input
//             type="email"
//             className="form-control"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Email"
//           />
//         </div>

//         <div className="form-group">
//           <label>Password</label>
//           <input
//             type="password"
//             className="form-control"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="******"
//           />
//         </div>

//         <div className="form-group">
//           <label>Confirm Password</label>
//           <input
//             type="password"
//             className="form-control"
//             value={confPassword}
//             onChange={(e) => setConfPassword(e.target.value)}
//             placeholder="******"
//           />
//         </div>

//         <div className="form-group">
//           <label>Profile Image</label>
//           <input
//             type="file"
//             className="form-control"
//             onChange={handleImageChange}
//           />
//           {previewImage && (
//             <img src={previewImage} alt="Profile Preview" className="img-preview" />
//           )}
//         </div>

//         <div className="form-group">
//           <label>Company</label>
//           <input
//             type="text"
//             className="form-control"
//             value={company}
//             onChange={(e) => setCompany(e.target.value)}
//             placeholder="Company"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field1</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field1}
//             onChange={(e) => setField1(e.target.value)}
//             placeholder="Custom Field 1"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field2</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field2}
//             onChange={(e) => setField2(e.target.value)}
//             placeholder="Custom Field 2"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field3</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field3}
//             onChange={(e) => setField3(e.target.value)}
//             placeholder="Custom Field 3"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field4</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field4}
//             onChange={(e) => setField4(e.target.value)}
//             placeholder="Custom Field 4"
//           />
//         </div>

//         <div className="form-group">
//           <label>Field5</label>
//           <input
//             type="text"
//             className="form-control"
//             value={field5}
//             onChange={(e) => setField5(e.target.value)}
//             placeholder="Custom Field 5"
//           />
//         </div>

//         <div className="form-group text-center">
//           <button type="submit" className="btn btn-primary">
//             Update Profile
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ProfilePage;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SignatureCanvas from 'react-signature-canvas';
import "./CSS/FormEditUser.css"; // Reuse the same CSS as FormEditUser
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice";

const ProfilePage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [profileImage, setProfileImage] = useState(null); // Change to handle file input
  const [company, setCompany] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [field5, setField5] = useState("");
  const [activeTab, setActiveTab] = useState('upload'); // New state for active tab
  const [msg, setMsg] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const sigPad = useRef({});
  const [userManagers, setUserManagers] = useState([]);

  const { managers } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getManagers());
    const getProfile = async () => {
      try {
        const response = await axios.get("https://back-itonline.algosaibi.sa/profile");
        const data = response.data;
        setName(data.name);
        setEmail(data.email);
        setCompany(data.company);
        setField1(data.field1);
        setField2(data.field2);
        setField3(data.field3);
        setField4(data.field4);
        setField5(data.field5);
        if (data.profileImage) {
          setProfileImage(data.profileImage);
          setPreviewImage(`https://back-itonline.algosaibi.sa/${data.profileImage}`);
        }

        setUserManagers(data.managers.split(","));
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getProfile();
  }, [dispatch]);

  // Handle profile update
  const updateProfile = async (e) => {
    e.preventDefault();
    if (!profileImage) {
      alert("Please upload a profile image.");
      return; // Stop the form submission if no image is selected
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confPassword", confPassword);
    formData.append("profileImage", profileImage);
    formData.append("company", company);
    formData.append("field1", field1);
    formData.append("field2", field2);
    formData.append("field3", field3);
    formData.append("field4", field4);
    formData.append("field5", field5);

    try {
      await axios.patch("https://back-itonline.algosaibi.sa/profile", formData);
      alert("Profile updated successfully!"); // Add this line for a success alert
    } catch (error) {
      if (error.response) {
       // alert(error.response.data.msg || "Error updating profile. Please try again."); // Add this line for an error alert
      } else {
       // alert("An error occurred. Please try again."); // Generic error message if response is not available
      }
    }
  };

  // Handle image preview
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const filePath = await uploadToServer(file);
    setProfileImage(filePath);
    setPreviewImage(URL.createObjectURL(file));
  };

  const saveSignature = async () => {
    if (sigPad.current.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      const signatureDataURL = sigPad.current.toDataURL('image/png');
      const blob = await (await fetch(signatureDataURL)).blob();
      const file = new File([blob], "signature.png", { type: "image/png" });
      
      const filePath = await uploadToServer(file);
      setProfileImage(filePath);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const clearSignature = () => {
    sigPad.current.clear();
    setProfileImage(null);
    setPreviewImage(null);
  };

  const uploadToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("File uploaded successfully:", response.data);
      return response.data.file; // Assuming the server returns the file path
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  return (
    <div className="form-container">
      <h1 className="form-title">Edit Your Profile</h1>
      <form onSubmit={updateProfile}>
        <p className="text-center text-danger">{msg}</p>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        {/* <div className="form-group">
          <label>Signature</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Profile Preview"
              className="img-preview"
            />
          )}
        </div> */}

       
      <div className="form-group">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button 
            type="button"
              className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`} 
              onClick={() => setActiveTab('upload')}
            >
              Upload Signature
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button 
            type="button"
              className={`nav-link ${activeTab === 'sign' ? 'active' : ''}`} 
              onClick={() => setActiveTab('sign')}
            >
              Draw Signature
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <br />
          <div className={`tab-pane fade ${activeTab === 'upload' ? 'show active' : ''}`}>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className={`tab-pane fade ${activeTab === 'sign' ? 'show active' : ''}`}>
            <label>Signature</label>
            <SignatureCanvas
              ref={sigPad}
              canvasProps={{width: 500, height: 200, className: 'signature-canvas'}}
            />

                 <div className="mt-2">
                   <button type="button" className="btn btn-primary me-2" onClick={saveSignature}>Save Signature</button>
                  <button type="button" className="btn btn-secondary" onClick={clearSignature}>Clear</button>
                 </div>
          </div>
        </div>
      </div>
      {previewImage && (
        <div className="preview">
          <img src={previewImage} alt="Profile Preview" className="img-preview" />
        </div>
      )}

        <div className="form-group">
          <label>Managers</label>
          {userManagers?.map((manager) => {
            const foundManager = managers?.find(
              (m) => m.uuid === manager.trim()
            );
            return foundManager ? foundManager.name : null;
          })
            .filter((name) => name !== null) // Filter out null values
            .map((manager, index) => (
              <input
                key={index}
                type="text"
                className="form-control"
                value={manager}
                placeholder="Manager"
                disabled={true}
              />
            ))}
        </div>

        <div className="form-group">
          <label>Company</label>
          <input
            type="text"
            className="form-control"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company"
          />
        </div>

        {/*<div className="form-group">
          <label>Field1</label>
          <input
            type="text"
            className="form-control"
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            placeholder="Custom Field 1"
          />
        </div>

        <div className="form-group">
          <label>Field2</label>
          <input
            type="text"
            className="form-control"
            value={field2}
            onChange={(e) => setField2(e.target.value)}
            placeholder="Custom Field 2"
          />
        </div>

        <div className="form-group">
          <label>Field3</label>
          <input
            type="text"
            className="form-control"
            value={field3}
            onChange={(e) => setField3(e.target.value)}
            placeholder="Custom Field 3"
          />
        </div>

        <div className="form-group">
          <label>Field4</label>
          <input
            type="text"
            className="form-control"
            value={field4}
            onChange={(e) => setField4(e.target.value)}
            placeholder="Custom Field 4"
          />
        </div>

         <div className="form-group">
          <label>Field5</label>
          <input
            type="text"
            className="form-control"
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            placeholder="Custom Field 5"
          />
        </div> */}

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfilePage;
