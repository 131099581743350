import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRequest } from '../features/requestSlice';
import { getManagers } from '../features/authSlice';

const RequestForm = () => {
    const dispatch = useDispatch();
    const { managers, isLoading, error } = useSelector(state => state.auth); // or state.managers if in a separate slice
    const [selectedManagers, setSelectedManagers] = useState([]);

    useEffect(() => {
        dispatch(getManagers());
    }, [dispatch]);

    if (isLoading) return <div>Loading...</div>;
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createRequest(selectedManagers));
    };

    const handleManagerSelect = (e) => {
        const managerId = parseInt(e.target.value);
        if (!selectedManagers.includes(managerId)) {
            setSelectedManagers([...selectedManagers, managerId]);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Create New Request</h2>
            <div>
                <label>Select Managers:</label>
                <select onChange={handleManagerSelect}>
                    <option value="">Select a manager</option>
                    {Array.isArray(managers) && managers.length > 0 ? (
                        managers.filter(user => user.role === 'manager').map(manager => (
                            <option key={manager.id} value={manager.id}>{manager.name}</option>
                        ))
                    ) : (
                        <option disabled>No managers available</option>
                    )}
                </select>
            </div>
            <div>
                <h3>Selected Managers:</h3>
                <ul>
                    {selectedManagers.map((managerId, index) => (
                        <li key={index}>
                            {managers && managers.find(user => user.id === managerId)?.name}
                        </li>
                    ))}
                </ul>
            </div>
            <button type="submit">Submit Request</button>
        </form>
    );
};

export default RequestForm;