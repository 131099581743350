import React, { useEffect } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import { Col, Row, } from 'react-bootstrap';

import EditForm from "../components/EditForm";

const EditFormPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);
    return (
        <Layout>
            <h1 className="title">Drafted Form</h1>
            <h2 className="subtitle">Fixd Asset Requisition Form</h2>
            <Row className="mb-4">
            </Row>
            <Row className="justify-content-md-center">
                <EditForm />
            </Row>
        </Layout>
    );
};

export default EditFormPage;
