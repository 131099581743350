import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import Layout from "../Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Col, Row, Button, Card, ListGroup, Modal, Accordion } from 'react-bootstrap';
import FormItem2 from "../../components/FormItem2";

const FormItem2Page = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            navigate("/");
        }
    }, [isError, navigate]);
    return (
        <Layout>
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <FormItem2 />
                </Col>
            </Row>
        </Layout>
    );
};

export default FormItem2Page;