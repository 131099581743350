import React from "react";
import { useSelector } from "react-redux";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
            <h1 className='title'>Welcome</h1>
            <h1 className='subtitle'>Good to see you again, <strong>{user && user.name}</strong>!</h1>
            <h2>This platform is designed to help you manage your requests efficiently.</h2>
    </div>
  );
};

export default Welcome;
