import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./CSS/FormEditUser.css";
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice";

const CompanyForm = () => {
  const [name, setName] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [msg, setMsg] = useState("");
  const [managersData, setManagersData] = useState([]);
  const [managersDataArray, setManagersDataArray] = useState([]);
  const [previewImage, setPreviewImage] = useState(""); // For previewing the uploaded image
  const { id: companyId } = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    const exists = managersDataArray.some(
      (manager) => manager.uuid === item.uuid
    );

    if (exists) {
      const filteredManagers = managersDataArray.filter(
        (manager) => manager.uuid !== item.uuid
      );
      setManagersDataArray(filteredManagers);
      const filterIds = managersData.filter((id) => id !== item.uuid);
      setManagersData(filterIds);
    } else {
      // If the manager does not exist, add it
      setManagersDataArray([...managersDataArray, item]);
      setManagersData([...managersData, item.uuid]);
    }
  };

  const dispatch = useDispatch();

  const { managers } = useSelector((state) => state.auth);

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(
          `https://back-itonline.algosaibi.sa/companies/${companyId}`
        );
        const data = response.data.company;
        setManagersData(data.users?.map((id) => id.uuid));
        setManagersDataArray(data.users);
        setName(data.name);

        if (data.profileImage) {
          setPreviewImage(`https://back-itonline.algosaibi.sa/${data.profileImage}`);
          setProfileImage(data.profileImage);
        }
      } catch (error) {
        if (error.response) {
          // setMsg(error.response.data.msg);
        }
      }
    };
    getUserById();
  }, [companyId]);

  const updateUser = async (e) => {
    e.preventDefault();
    const managerIds = managersDataArray?.map((id) => id.uuid);
    if (!name || managerIds.length < 7 || !profileImage) {
      alert("Please fill in all fields");
      return;
    }

    const data = {
      name,
      managerIds,
      profileImage,
    };

    try {
      if (companyId != "0") {
        const response = await axios.put(
          `https://back-itonline.algosaibi.sa/company/${companyId}`,
          data
        );
        if (response.data.success) {
          navigate("/companies");
        }
      } else {
        const response = await axios.post(
          "https://back-itonline.algosaibi.sa/company",
          data
        );
        if (response.data.success) {
          navigate("/companies");
        }
      }
    } catch (error) {
      console.error("Error saving company:", error);
    }
  };

  const uploadToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/api/upload",
        formData
      );
      return response.data.file;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Handle image preview
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const image = await uploadToServer(file);
    setProfileImage(image);
    setPreviewImage(URL.createObjectURL(file));
  };

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  return (
    <div className="form-container">
      <h1 className="form-title">Edit Company Profile</h1>
      <form onSubmit={updateUser}>
        <p className="text-center text-danger">{msg}</p>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>

        <div className="mb-3" ref={dropdownRef}>
          <label>Select Manager(s)</label>
          <br />
          <div className="dropdown w-100">
            <button
              className="form-control dropdown-toggle rounded-xl border border-skylight  text-charcoal overflow-auto"
              type="button"
              onClick={handleToggleDropdown}
              aria-expanded={isOpen}
            >
              {managersDataArray?.map((item, index) => (
                <span key={index}>
                  {item.name}
                  {index < managersDataArray.length - 1 ? ", " : ""}
                </span>
              ))}
            </button>
            <ul className={`dropdown-menu ${isOpen ? "show w-100" : ""}`}>
              {managers?.map((item, index) =>
                item.role == "manager" ? (
                  <li key={index} onClick={() => handleItemClick(item)}>
                    <div
                      className={`dropdown-item ${
                        managersData?.includes(item.uuid) ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </div>
                  </li>
                ) : (
                  ""
                )
              )}
            </ul>
          </div>
        </div>

        <div className="form-group">
          <label>Company Logo</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Profile Preview"
              className="img-preview"
            />
          )}
        </div>

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            {companyId != "0" ? "Update Company" : "Add Company"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;
