import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Button } from "react-bootstrap";

const Companieslist = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const response = await axios.get("https://back-itonline.algosaibi.sa/companies");
    if (response.data.success) {
      setCompanies(response.data.companies);
    } else {
      console.log(response.data.message);
    }
  };

  const deleteCompany = async (companyId) => {
    await axios.delete(`https://back-itonline.algosaibi.sa/company/${companyId}`);
    getCompanies();
  };

  return (
    <div>
      <h1 className="title">Companies</h1>
      <h2 className="subtitle">List of Companies</h2>
      <Link to="/company/edit/0" className="button is-primary mb-2">
        Add New
      </Link>
      {companies.length === 0 ? (
        <p>No company found.</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Logo</th>
              <th>Managers</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((item, index) => (
              <tr key={item.uuid}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>
                  <a
                    href={`https://back-itonline.algosaibi.sa/${item.profileImage}`}
                    target="_blank"
                  >
                    Image
                  </a>
                </td>
                <td>
                  {item.users.map((item, i) => (
                    <div>
                      {i + 1} {item.name}
                      <br />
                    </div>
                  ))}
                </td>
                <td>
                  <Link to={`/company/edit/${item.uuid}`}>
                    <button
                      className="button is-small is-info"
                      style={{ marginRight: 10 }}
                    >
                      Edit
                    </button>
                  </Link>

                  <button
                    onClick={() => deleteCompany(item.uuid)}
                    className="button is-small is-danger"
                  >
                    Delete Company
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default Companieslist;
