import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./CSS/FormEditUser.css";
import { useDispatch, useSelector } from "react-redux";
import { getManagers } from "../features/authSlice";

const FormEditUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [role, setRole] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [profileImage, setProfileImage] = useState(null); // Change to handle file input
  const [company, setCompany] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [field5, setField5] = useState("");
  const [msg, setMsg] = useState("");
  const [managersData, setManagersData] = useState([]);
  const [managersDataArray, setManagersDataArray] = useState([]);
  const [previewImage, setPreviewImage] = useState(""); // For previewing the uploaded image
  const navigate = useNavigate();
  const [appointedAdmin, setAppointedAdmin] = useState();
  const [appointedAdminName, setAppointedAdminName] = useState();
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setManagersDataArray([item]);
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const { managers } = useSelector((state) => state.auth); // or state.managers if in a separate slice

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`https://back-itonline.algosaibi.sa/users/${id}`);
        const data = response.data;

        setName(data.name);
        setEmail(data.email);
        setRole(data.role);
        setCompany(data.company);
        setField1(data.field1);
        setField2(data.field2);
        setField3(data.field3);
        setField4(data.field4);
        setField5(data.field5);
        setManagersDataArray([data.manager]);

        if (data.profileImage) {
          setPreviewImage(`https://back-itonline.algosaibi.sa/${data.profileImage}`);
        }
      } catch (error) {
        if (error.response) {
          setMsg(error?.response?.data?.msg);
        }
      }
    };
    getUserById();
  }, [id]);

  const uploadToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://back-itonline.algosaibi.sa/api/upload",
        formData
      );
      console.log("File uploaded successfully:", response.data);
      return response.data.file;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Handle user update
  const updateUser = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confPassword", confPassword);
    formData.append("role", role);
    formData.append("profileImage", profileImage);
    formData.append("company", company);
    formData.append("field1", field1);
    formData.append("field2", field2);
    formData.append("field3", field3);
    formData.append("field4", field4);
    formData.append("field5", field5);
    formData.append("manager", managersDataArray[0]?.uuid);

    try {
      await axios.patch(`https://back-itonline.algosaibi.sa/users/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("User updated successfully!");
    } catch (error) {
      console.log(error);
      alert("Failed to update user. Please try again.");
    }
  };

  // Handle image preview
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const filePath = await uploadToServer(file);
    setProfileImage(filePath);
    setPreviewImage("https://back-itonline.algosaibi.sa/" + filePath);
  };

  useEffect(() => {
    dispatch(getManagers());
  }, [dispatch]);

  useEffect(() => {
    if (managersData && managers) {
      const filteredManagers = managers.filter((manager) =>
        managersData?.includes(manager.uuid)
      );
      setManagersDataArray(filteredManagers);

      const filterSuperAdmin = managers.filter((manager) =>
        appointedAdmin?.includes(manager.uuid)
      );
      setAppointedAdminName(filterSuperAdmin[0]);
    }

    // console.log(filteredManagers);
  }, [managersData]);

  return (
    <div className="form-container">
      <h1 className="form-title">Edit User Profile</h1>
      <form onSubmit={updateUser}>
        <p className="text-center text-danger">{msg}</p>

        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        <div className="form-group">
          <label>Confirm Password</label>
          <input
            type="password"
            className="form-control"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
            placeholder="******"
          />
        </div>

        <div className="form-group">
          <label>Role</label>
          <select
            className="form-control"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="manager">Manager</option>
          </select>
        </div>

        <div className="form-group">
          <label>Signature</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
          />
          {previewImage && (
            <img
              src={previewImage}
              alt="Profile Preview"
              className="img-preview"
            />
          )}
        </div>

        <div className="form-group">
          <label>Company</label>
          <input
            type="text"
            className="form-control"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company"
          />
        </div>
        {role === "user" && (
          <div className="mb-3" ref={dropdownRef}>
            <label>Select Manager</label>
            <br />
            <div className="dropdown w-100">
              <button
                className="form-control dropdown-toggle rounded-xl border border-skylight text-charcoal"
                type="button"
                onClick={handleToggleDropdown}
                aria-expanded={isOpen}
              >
                {managersDataArray && managersDataArray.length > 0 ? (
                  <span>{managersDataArray[0]?.name}</span>
                ) : (
                  <span>Select a manager</span>
                )}
              </button>
              <ul className={`dropdown-menu ${isOpen ? "show w-100" : ""}`}>
                {managers?.map((item, index) =>
                  item.role === "manager" ? (
                    <li key={index} onClick={() => handleItemClick(item)}>
                      <div
                        className={`dropdown-item ${
                          managersDataArray?.[0]?.uuid === item.uuid
                            ? "active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        {item.name}
                      </div>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        )}

        {/* <div className="form-group">
          <label>Field1</label>
          <input
            type="text"
            className="form-control"
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            placeholder="Custom Field 1"
          />
        </div>

        <div className="form-group">
          <label>Field2</label>
          <input
            type="text"
            className="form-control"
            value={field2}
            onChange={(e) => setField2(e.target.value)}
            placeholder="Custom Field 2"
          />
        </div>

        <div className="form-group">
          <label>Field3</label>
          <input
            type="text"
            className="form-control"
            value={field3}
            onChange={(e) => setField3(e.target.value)}
            placeholder="Custom Field 3"
          />
        </div>

        <div className="form-group">
          <label>Field4</label>
          <input
            type="text"
            className="form-control"
            value={field4}
            onChange={(e) => setField4(e.target.value)}
            placeholder="Custom Field 4"
          />
        </div>

        <div className="form-group">
          <label>Field5</label>
          <input
            type="text"
            className="form-control"
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            placeholder="Custom Field 5"
          />
        </div> */}

        <div className="form-group text-center">
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEditUser;
